import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContextToastComponent } from "./context-toast.component";
import { IonicModule } from "@ionic/angular";

@NgModule({
  declarations: [ContextToastComponent],
  exports: [ContextToastComponent],
  imports: [CommonModule, IonicModule],
})
export class ContextToastModule {}
