/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { RouteID } from "@ionic/core";

@Injectable({
  providedIn: "root",
})
export class ModalRoutingService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public navigateForward(id: string, component: any, setAnimation?: boolean): void {
    const ionNavElement = this.document.getElementById(id) as HTMLIonNavElement;
    ionNavElement.animated = true;
    if (setAnimation) ionNavElement.animated = false;
    ionNavElement.push(component);
  }

  public navigateBack(id: string, setAnimation?: boolean): void {
    const ionNavElement = this.document.getElementById(id) as HTMLIonNavElement;
    if (setAnimation) ionNavElement.animated = false;
    ionNavElement.pop();
  }

  public navigateHome(id: string): void {
    const ionNavElement = this.document.getElementById(id) as HTMLIonNavElement;
    ionNavElement.animated = false;
    ionNavElement.popToRoot();
  }

  public getRouteId(id: string): Promise<RouteID | undefined> {
    return (this.document.getElementById(id) as HTMLIonNavElement)?.getRouteId();
  }
}
