import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "./enums";
import { AuthGuard } from "./guards/auth/auth.guard";

const routes: Routes = [
  {
    path: AppRoutes.welcome,
    loadChildren: () => import("./pages/welcome/welcome.module").then(m => m.WelcomeModule),
    data: { displayIntroImage: true },
  },
  {
    path: AppRoutes.login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginModule),
    data: { displayIntroImage: true },
  },
  {
    path: AppRoutes.map,
    loadChildren: () => import("./pages/map/map.module").then(m => m.MapModule),
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: AppRoutes.map,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
