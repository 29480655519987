import { createAction, props } from "@ngrx/store";
import { DataLayer } from "src/app/enums";
import { BaseMapLayerConfig, MapLayerWMSConfig, WMSLayerLastUpdate } from "src/app/interfaces";
import {
  BlackSpotDto,
  BlackSpotDataDto,
  CatchmentDto,
  IslandDto,
  LegacyControllerGetCatchmentDataRequestParams,
  LegacyControllerGetCatchmentStationsRequestParams,
  LegacyControllerGetStationDataRequestParams,
  LegacyControllerGetWatershedCatchmentsRequestParams,
  LegacyControllerGetWatershedsRequestParams,
  StationDataDto,
  StationDto,
  WatershedDto,
  ZoneDataDto,
  LegacyControllerGetBlackSpotDataRequestParams,
  LegacyControllerGetAllCatchmentsRequestParams,
  LegacyControllerGetAnyCatchmentStationsRequestParams,
  LegacyControllerGetAnyCatchmentBlackSpotsRequestParams,
  LegacyControllerGetCatchmentBlackSpotsRequestParams,
} from "src/app/services/api";

export const resetState = createAction("[WATERSHED] Reset State");

export const setMapLayer = createAction("[WATERSHED] Set Map Layer", props<{ mapLayer: BaseMapLayerConfig }>());
export const setBaseMapLayerConfig = createAction("[WATERSHED] Set Base Map Layer Config", props<{ mapLayerConfig: BaseMapLayerConfig }>());
export const setRiversLayer = createAction("[WATERSHED] Set Rivers Layer", props<{ riversLayer: MapLayerWMSConfig }>());
export const setMapLayerWMS = createAction("[WATERSHED] Set Map Layer WMS", props<{ mapLayerWMS: MapLayerWMSConfig }>());
export const setDataLayer = createAction("[WATERSHED] Set Data Layer", props<{ dataLayer: DataLayer }>());
export const setIslandId = createAction("[WATERSHED] Set Island Id", props<{ islandId: string }>());
export const setWatershedId = createAction("[WATERSHED] Set Watershed Id", props<{ watershedId: number }>());
export const setCatchmentId = createAction("[WATERSHED] Set Catchment Id", props<{ catchmentId: number }>());
export const setStationCode = createAction("[WATERSHED] Set Station Code", props<{ stationCode: string }>());
export const setBlackspotId = createAction("[WATERSHED] Set Blackspot Id", props<{ blackspotId: string }>());
export const resetToIslandsView = createAction("[WATERSHED] Reset To Islands View");

export const setHoveredIslandId = createAction(
  "[WATERSHED] Set Hovered Island Id",
  props<{ islandId: string; hoveredIslandId: string }>(),
);
export const setHoveredWatershedId = createAction(
  "[WATERSHED] Set Hovered Watershed Id",
  props<{ watershedId: number; hoveredWatershedId: number }>(),
);
export const setHoveredCatchmentId = createAction(
  "[WATERSHED] Set Hovered Catchment Id",
  props<{ catchmentId: number; hoveredCatchmentId: number; catchmentName?: string }>(),
);
export const setHoveredStationCode = createAction(
  "[WATERSHED] Set Hovered Station Code",
  props<{ stationCode: string; hoveredStationCode: string }>(),
);

export const setClicked = createAction(
  "[WATERSHED] Set Clicked Element",
  props<{clickedElementId: string}>(),
);

export const setCatchmentWaterLevelStationCode = createAction(
  "[WATERSHED] Set Catchment Water Level Station Code",
  props<{ catchmentWaterLevelStationCode: string }>(),
);
export const setCatchmentPrecipitationStationCode = createAction(
  "[WATERSHED] Set Catchment Precipitation Station Code",
  props<{ catchmentPrecipitationStationCode: string }>(),
);

export const setWatershedName = createAction(
  "[WATERSHED] Set Watershed Name",
  props<{ watershedNameId: number; watershedName: string }>(),
);

export const loadIslands = createAction("[WATERSHED] Load Islands");
export const loadIslandsSuccess = createAction("[WATERSHED] Load Islands Success", props<{ islands: IslandDto[] }>());
export const loadIslandsFailure = createAction("[WATERSHED] Load Islands Failure");

export const loadWatersheds = createAction("[WATERSHED] Load Watersheds", props<LegacyControllerGetWatershedsRequestParams>());
export const loadWatershedsSuccess = createAction("[WATERSHED] Load Watersheds Success", props<{ watersheds: WatershedDto[] }>());
export const loadWatershedsFailure = createAction("[WATERSHED] Load Watersheds Failure");

export const removeWatershedsPolygons = createAction("[WATERSHED] Remove Watersheds Polygons");
export const removeIslandsPolygons = createAction("[WATERSHED] Remove Islands Polygons");

export const loadAllCatchments = createAction(
  "[WATERSHED] Load Island Catchments",
  props<LegacyControllerGetAllCatchmentsRequestParams>(),
);
export const loadAllCatchmentsSuccess = createAction(
  "[WATERSHED] Load Island Catchments Success",
  props<{ catchments: CatchmentDto[] }>(),
);
export const loadAllCatchmentsFailure = createAction("[WATERSHED] Load Island Catchments Failure");

export const loadWatershedCatchmentData = createAction(
  "[WATERSHED] Load Watershed Catchment Data",
  props<LegacyControllerGetCatchmentDataRequestParams>(),
);
export const loadWatershedCatchmentDataSuccess = createAction(
  "[WATERSHED] Load Watershed Catchment Data Success",
  props<{ catchmentId: number; data: ZoneDataDto }>(),
);
export const loadWatershedCatchmentDataFailure = createAction("[WATERSHED] Load Watershed Catchment Data Failure");

export const loadWatershedCatchmentStations = createAction(
  "[WATERSHED] Load Watershed Catchment Stations",
  props<LegacyControllerGetCatchmentStationsRequestParams>(),
);
export const loadWatershedCatchmentStationsSuccess = createAction(
  "[WATERSHED] Load Watershed Catchment Stations Success",
  props<{ catchmentId: number; stations: StationDto[] }>(),
);
export const loadWatershedCatchmentStationsFailure = createAction("[WATERSHED] Load Watershed Catchment Stations Failure");

export const loadAnyCatchmentStations = createAction(
  "[WATERSHED] Load Any Catchment Stations",
  props<LegacyControllerGetAnyCatchmentStationsRequestParams>(),
);
export const loadAnyCatchmentStationsSuccess = createAction(
  "[WATERSHED] Load Any Catchment Stations Success",
  props<{ catchmentId: number; stations: StationDto[] }>(),
);
export const loadAnyCatchmentStationsFailure = createAction("[WATERSHED] Load Any Catchment Stations Failure");

export const loadStationData = createAction("[WATERSHED] Load Station Data", props<LegacyControllerGetStationDataRequestParams>());
export const loadStationDataSuccess = createAction(
  "[WATERSHED] Load Station Data Success",
  props<{ stationCode: string; stationData: StationDataDto }>(),
);
export const loadStationDataFailure = createAction("[WATERSHED] Load Station Data Failure");

export const loadWMSLayerLastUpdate = createAction("[WATERSHED] Load WMS Layer Last");
export const loadWMSLayerLastUpdateSuccess = createAction(
  "[WATERSHED] Load WMS Layer Last Success",
  props<{ lastUpdatesLayerWMS: WMSLayerLastUpdate[] }>(),
);
export const loadWMSLayerLastUpdateFailure = createAction("[WATERSHED] Load WMS Layer Last Failure");

export const loadAnyCatchmentBlackspots = createAction(
  "[WATERSHED] Load Any Catchment Blackspots",
  props<LegacyControllerGetAnyCatchmentBlackSpotsRequestParams>(),
);
export const loadAnyCatchmentBlackspotsSuccess = createAction(
  "[WATERSHED] Load Any Catchment Blackspots Success",
  props<{ catchmentId: number; data: BlackSpotDto[] }>(),
);
export const loadAnyCatchmentBlackspotsFailure = createAction("[WATERSHED] Load Any Catchment Blackspots Failure");

export const loadBlackspotData = createAction(
  "[WATERSHED] Load Blackspot Data",
  props<LegacyControllerGetBlackSpotDataRequestParams>());
export const loadBlackspotDataSuccess = createAction(
  "[WATERSHED] Load Blackspots Data Success",
  props<{ blackspotId: string; blackspotsData: BlackSpotDataDto }>(),
);
export const loadBlackspotDataFailure = createAction("[WATERSHED] Load Blackspots Data Failure");
