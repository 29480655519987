import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderByAlertLevelPipe } from "./order-by-alert-level.pipe";

@NgModule({
  declarations: [OrderByAlertLevelPipe],
  imports: [CommonModule],
  exports: [OrderByAlertLevelPipe],
})
export class OrderByAlertLevelModule {}
