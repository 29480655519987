import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParseValueStationsPipe } from "./parse-value-stations.pipe";

@NgModule({
  declarations: [ParseValueStationsPipe],
  imports: [CommonModule],
  exports: [ParseValueStationsPipe],
})
export class ParseValueStationsPipeModule {}
