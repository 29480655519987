import { TranslateService } from "@ngx-translate/core";
import { SwUpdateService } from "../sw-updater/sw-updater.service";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "src/app/constants";

export function initApplication(swUpdater: SwUpdateService, translateService: TranslateService): Function {
  return () => {
    swUpdater.checkForUpdate();
    try {
      const userLang = navigator.language.split("-")[0];
      translateService.setDefaultLang(AVAILABLE_LANGUAGES.includes(userLang) && userLang || DEFAULT_LANGUAGE);
    } catch (e) {
      console.error("Setting Language Problem", e);
    }
  };
}
