<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button icon="arrow-back-outline" [text]="'CATCHMENT' | translate" (click)="closeStation()"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="station">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="station">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="(station$ | async)?.id + ' - ' + (station$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="station__content">
        @if ((station$ | async)?.type === "1") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadWL
                        ? ('LAST_UPDATE'
                          | translate: { date: (station$ | async)?.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadWL
                "
            ></p>
        }

        @if ((station$ | async)?.type === "0") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadR60
                        ? ('LAST_UPDATE'
                          | translate: { date: (station$ | async)?.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadR60
                "
            ></p>
        }

        @if ((station$ | async)?.type === "2") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadR60
                        ? ('LAST_UPDATE_METEO'
                          | translate: { date: (station$ | async)?.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadR60
                "
            ></p>
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadWL
                        ? ('LAST_UPDATE_HIDRO'
                          | translate: { date: (station$ | async)?.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadWL
                "
            ></p>
        }

        @if ((station$ | async)?.type === "1" || (station$ | async)?.type === "2") {
            <p class="station__content__title --h2" [innerHTML]="'WATER_LEVEL' | translate"></p>

            <app-chart
                chartType="line"
                [alertLevels]="(stationData$ | async)?.alertLevels"
                [data]="(stationData$ | async)?.waterLevel"
            ></app-chart>
        }

        @if ((station$ | async)?.type === "0" || (station$ | async)?.type === "2") {
            <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.PRECIPITATION' | translate"></p>

            <!-- Hack to allow for straight top borders without touching the app-chart component -->
            <div>
                <app-chart
                    chartType="bar"
                    [data]="
                        (stationData$ | async)?.precipitation10
                            ? (stationData$ | async)?.precipitation10
                            : (stationData$ | async)?.precipitation60
                    "
                    [precipitationLast1h]="
                        'PRECIPITATION_UNIT'
                            | translate: { value: (station$ | async)?.precipitationLast1h | number: '1.0-1' : translateService.defaultLang }
                    "
                    [precipitationLast24h]="
                        'PRECIPITATION_UNIT'
                            | translate
                                : { value: (station$ | async)?.precipitationLast24h | number: '1.0-1' : translateService.defaultLang }
                    "
                ></app-chart>
            </div>

            <!-- If there's temeprature data show station__content__card for temperature. If not, don't.-->
            @if (hasTemperatureData$ | async) {
                <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.TEMPERATURE' | translate"></p>

                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyTemperatureData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary">
                        <img src="assets/images/temperature.png" />
                        <div>
                            <p
                                class="station__content__card__summary__title --h1"
                                [innerHTML]="
                                    'TEMPERATURE_UNIT'
                                        | translate: { value: temperature$ | async | number: '1.0-2' : translateService.defaultLang }
                                "
                            ></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                            ></p>
                        </div>
                    </ion-card>

                    <ion-card class="station__content__card__hours">
                        @for (value of temperatureHours$ | async; track value.timestamp) {
                            <div class="station__content__card__hours__item">
                                <p class="--small" [innerHTML]="value.timestamp"></p>
                                <img src="assets/images/temperature.png" />
                                <p
                                    class="--small"
                                    [innerHTML]="
                                        'TEMPERATURE_UNIT'
                                            | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                    "
                                ></p>
                            </div>
                        }
                    </ion-card>
                </div>
            }

            <!-- If there's wind data show station__content__card for wind. If not, don't.-->
            @if (hasWindData$ | async) {
                <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.WIND' | translate"></p>

                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyWindData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary">
                        <img src="assets/images/wind.png" />
                        <div>
                            <p
                                class="station__content__card__summary__title --h1"
                                [innerHTML]="
                                    'WIND_UNIT' | translate: { value: wind$ | async | number: '1.0-2' : translateService.defaultLang }
                                "
                            ></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                            ></p>
                        </div>
                    </ion-card>

                    <ion-card class="station__content__card__hours">
                        @for (value of windHours$ | async; track value.timestamp) {
                            <div class="station__content__card__hours__item">
                                <p class="--small" [innerHTML]="value.timestamp"></p>
                                <img src="assets/images/wind.png" />
                                <p
                                    class="--small"
                                    [innerHTML]="
                                        'WIND_UNIT' | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                    "
                                ></p>
                            </div>
                        }
                    </ion-card>
                </div>
            }

            <!-- If there's humidity data show station__content__card for humidity. If not, don't.-->
            @if (hasHumidityData$ | async) {
                <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.HUMIDITY' | translate"></p>

                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyHumidityData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary">
                        <img src="assets/images/humidity.png" />
                        <div>
                            <p
                                class="station__content__card__summary__title --h1"
                                [innerHTML]="
                                    'HUMIDITY_UNIT'
                                        | translate: { value: humidity$ | async | number: '1.0-2' : translateService.defaultLang }
                                "
                            ></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                            ></p>
                        </div>
                    </ion-card>

                    <ion-card class="station__content__card__hours">
                        @for (value of humidityHours$ | async; track value.timestamp) {
                            <div class="station__content__card__hours__item">
                                <p class="--small" [innerHTML]="value.timestamp"></p>
                                <img src="assets/images/humidity.png" />
                                <p
                                    class="--small"
                                    [innerHTML]="
                                        'HUMIDITY_UNIT' | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                    "
                                ></p>
                            </div>
                        }
                    </ion-card>
                </div>
            }
        }
    </div>
</ion-content>
