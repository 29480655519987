import { Directive, ElementRef, OnInit } from "@angular/core";

enum TouchDirection {
  UP = "UP",
  DOWN = "DOWN",
}

@Directive({
  selector: "[appSuppressScrollEvent]",
})
export class SuppressScrollEventDirective implements OnInit {
  private yPreviousPosition: number;

  public constructor(private readonly el: ElementRef) {}

  public ngOnInit(): void {
    (this.el.nativeElement as HTMLElement).ontouchstart = (e: TouchEvent): void => {
      this.yPreviousPosition = e.touches[0].clientY;
    };

    (this.el.nativeElement as HTMLElement).ontouchmove = async (e: TouchEvent): Promise<void> => {
      let direction: TouchDirection;

      const scroll = await this.el.nativeElement.getScrollElement();
      const scrollPercentage = (scroll.scrollTop / (scroll.scrollHeight - this.el.nativeElement.clientHeight)) * 100;

      if (e.touches[0].clientY > this.yPreviousPosition) direction = TouchDirection.DOWN;
      else direction = TouchDirection.UP;

      if (
        (scrollPercentage > 0 && scrollPercentage < 100) ||
        (scrollPercentage === 0 && direction === TouchDirection.UP) ||
        (scrollPercentage === 100 && direction === TouchDirection.DOWN)
      ) {
        e.stopPropagation();
      }
    };
  }
}
