import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export interface Item {
  title: string;
  subtitle: string;
}

@Component({
  selector: "app-station-card",
  templateUrl: "./station-card.component.html",
  styleUrls: ["./station-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationCardComponent {
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public status?: number;
  @Input() public description: string;
  @Input() public descriptionIcon: string;
  @Input() public hovered: boolean;
  @Input() public items: Item[];
}
