import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject, filter, takeUntil, tap, withLatestFrom } from "rxjs";
import { BlackSpotDataDto, BlackSpotDto, CatchmentDto, DataPointDto, StationDataDto, StationDto } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-station",
  templateUrl: "./blackspot.component.html",
  styleUrls: ["./blackspot.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlackspotComponent {
  public readonly blackspotId$: Observable<string> = this.store.select(FromWatershed.selectBlackspotId);
  public readonly blackspotData$: Observable<BlackSpotDataDto> = this.store.select(FromWatershed.selectBlackspotData);
  public readonly blackspot$: Observable<BlackSpotDto> = this.store.select(FromWatershed.selectBlackspot);

  public readonly catchment$: Observable<CatchmentDto> = this.store.select(FromWatershed.selectWatershedCatchment);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  public current_timestamp = new Date();

  private unsubscribeSubject$: Subject<void>;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public ionViewWillEnter(): void {
    this.unsubscribeSubject$ = new Subject();
    this.blackspotId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(blackspotCode => !!blackspotCode),
        tap(blackspotCode => {
          this.store.dispatch(WatershedActions.loadBlackspotData({ blackspotCode }));
        }),
      )
      .subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(refreshState => !!refreshState),
        withLatestFrom(this.blackspotId$),
        filter(([, blackspotCode]) => !!blackspotCode),
        tap(([, blackspotCode]) => {
          this.store.dispatch(WatershedActions.loadBlackspotData({ blackspotCode }));
        }),
      )
      .subscribe();
  }

  public ionViewWillLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public resetToIslandsView(): void {
    this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
    this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: null }));
    this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
    this.store.dispatch(WatershedActions.resetToIslandsView());
  }

  public async closeStation(): Promise<void> {
    this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: null }));
  }
}
