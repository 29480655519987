import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as WatershedActions from "src/app/store/watershed/actions";
import { Observable } from "rxjs";
import { DataLayer } from "src/app/enums";
import { RootState } from "src/app/store";

@Component({
  selector: "app-hidrometeo-selector",
  templateUrl: "./hidrometeo-selector.component.html",
  styleUrls: ["./hidrometeo-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HidroMeteoSelectorComponent {
  public dataLayer: typeof DataLayer = DataLayer;
  public readonly dataLayer$: Observable<DataLayer> = this.store.select(FromWatershed.selectDataLayer);

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public selectDataToShow(dataLayer: DataLayer): void {
    this.store.dispatch(WatershedActions.setDataLayer({ dataLayer }));
  }
}
