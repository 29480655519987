/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of, catchError, map, mergeMap, tap, withLatestFrom, pairwise, startWith, first } from "rxjs";
import { LegacyService } from "src/app/services/api";
import { PLUJA_RADAR_URL } from "src/app/constants";
import { StationComponent } from "src/app/modals/station/station.component";
import { CatchmentComponent } from "src/app/modals/catchment/catchment.component";
import { WatershedComponent } from "src/app/modals/watershed/watershed.component";
import { ModalRoutingService } from "src/app/services/modal-routing/modal-routing.service";
import { select, Store } from "@ngrx/store";
import { RootState } from "..";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ModalIds } from "src/app/enums";
import { WMSLayerLastUpdate } from "src/app/interfaces";
import { WatershedsComponent } from "src/app/modals/watersheds/watersheds.component";
import { BlackspotComponent } from "src/app/modals/blackspot/blackspot.component";
import { IslandsComponent } from "src/app/modals/islands/islands.component";

@Injectable()
export class WatershedEffects {
  public readonly islandsComponent = IslandsComponent;
  public readonly watershedsComponent = WatershedsComponent;
  public readonly watershedComponent = WatershedComponent;
  public readonly catchmentComponent = CatchmentComponent;
  public readonly stationComponent = StationComponent;
  public readonly blackspotComponent = BlackspotComponent;

  public loadIslands$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadIslands),
      mergeMap(() =>
        this.legacyService.legacyControllerGetIslands().pipe(
          map(islands => WatershedActions.loadIslandsSuccess({ islands })),
          catchError(() => of(WatershedActions.loadIslandsFailure())),
        ),
      ),
    ),
  );

  public loadAllCatchments$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadAllCatchments),
      mergeMap((params) =>
        this.legacyService.legacyControllerGetAllCatchments(params).pipe(
          map(catchments => WatershedActions.loadAllCatchmentsSuccess({ catchments })),
          catchError(() => of(WatershedActions.loadAllCatchmentsFailure())),
        ),
      ),
    ),
  );

  public loadWatersheds$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadWatersheds),
      mergeMap((params) =>
        this.legacyService.legacyControllerGetWatersheds(params).pipe(
          map(watersheds => WatershedActions.loadWatershedsSuccess({ watersheds })),
          catchError(() => of(WatershedActions.loadWatershedsFailure())),
        ),
      ),
    ),
  );

  public loadWatershedCatchmentData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadWatershedCatchmentData),
      mergeMap(params =>
        this.legacyService.legacyControllerGetCatchmentData(params).pipe(
          map(data => WatershedActions.loadWatershedCatchmentDataSuccess({ catchmentId: params.catchmentId, data })),
          catchError(() => of(WatershedActions.loadWatershedCatchmentDataFailure())),
        ),
      ),
    ),
  );

  public loadWatershedCatchmentStations$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadWatershedCatchmentStations),
      mergeMap(params =>
        this.legacyService.legacyControllerGetCatchmentStations(params).pipe(
          map(stations => WatershedActions.loadWatershedCatchmentStationsSuccess({ catchmentId: params.catchmentId, stations })),
          catchError(() => of(WatershedActions.loadWatershedCatchmentStationsFailure())),
        ),
      ),
    ),
  );

  public loadAnyCatchmentStations$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadAnyCatchmentStations),
      mergeMap(params =>
        this.legacyService.legacyControllerGetAnyCatchmentStations(params).pipe(
          map(stations => WatershedActions.loadAnyCatchmentStationsSuccess({ catchmentId: params.catchmentId, stations })),
          catchError(() => of(WatershedActions.loadAnyCatchmentStationsFailure())),
        ),
      ),
    ),
  );

  public loadStationData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadStationData),
      mergeMap(params =>
        this.legacyService.legacyControllerGetStationData(params).pipe(
          map(stationData => WatershedActions.loadStationDataSuccess({ stationCode: params.stationCode, stationData })),
          catchError(() => of(WatershedActions.loadStationDataFailure())),
        ),
      ),
    ),
  );

  public loadBlackspotData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadBlackspotData),
      mergeMap(params =>
        this.legacyService.legacyControllerGetBlackSpotData(params).pipe(
          map(blackspotsData => WatershedActions.loadBlackspotDataSuccess({ blackspotId: params.blackspotCode, blackspotsData })),
          catchError(() => of(WatershedActions.loadBlackspotDataFailure())),
        ),
      ),
    ),
  );

  public setIslandId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setIslandId),
        tap(async ({ islandId }) => {
          if (!islandId) {
            const setAnimation: boolean = true;
            this.modalRoutingService.navigateBack(ModalIds.MapModal, setAnimation);
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.islandsComponent);
          } else {
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.watershedsComponent);
          }
        }),
      ),
    { dispatch: false },
  );

  public resetToIslandsView$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.resetToIslandsView),
        tap(async () => {
          this.modalRoutingService.navigateHome(ModalIds.MapModal);
          // const setAnimation: boolean = true;
          // this.modalRoutingService.navigateBack(ModalIds.MapModal, setAnimation);
          // this.modalRoutingService.navigateForward(ModalIds.MapModal, this.islandsComponent, false);
        }),
      ),
    { dispatch: false },
  );

  public setWatershedId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setWatershedId),
        tap(async ({ watershedId }) => {
          if (!watershedId) {
            // const setAnimation: boolean = true;
            // this.modalRoutingService.navigateBack(ModalIds.MapModal, setAnimation);
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.watershedsComponent);
          } else {
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.watershedComponent);
          }
        }),
      ),
    { dispatch: false },
  );

  public setCatchmentId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setCatchmentId),
        tap(async ({ catchmentId }) => {
          if (!catchmentId) {
            // const setAnimation: boolean = true;
            // this.modalRoutingService.navigateBack(ModalIds.MapModal, setAnimation);
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.watershedComponent);
          } else {
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.catchmentComponent);
          }
        }),
      ),
    { dispatch: false },
  );

  public setStationCode$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setStationCode),
        startWith({ stationCode: null }),
        pairwise(),
        tap(([{ stationCode: previousStationCode }, { stationCode }]) => {
          if (!stationCode) {
            this.modalRoutingService.navigateBack(ModalIds.MapModal);
          } else if (stationCode && !previousStationCode) {
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.stationComponent);
          }
          if (stationCode && previousStationCode) this.setHovered(previousStationCode, false);
          this.setHovered(stationCode ? stationCode : previousStationCode, !!stationCode);
        }),
      ),
    { dispatch: false },
  );

  public setBlackspotId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setBlackspotId),
        startWith({ blackspotId: null }),
        pairwise(),
        tap(([{ blackspotId: previousBlackspotId }, { blackspotId }]) => {
          if (!blackspotId) {
            this.modalRoutingService.navigateBack(ModalIds.MapModal);
          } else if (blackspotId && !previousBlackspotId) {
            this.modalRoutingService.navigateForward(ModalIds.MapModal, this.blackspotComponent);
          }
          if (blackspotId && previousBlackspotId) this.setHovered(previousBlackspotId, false);
          this.setHovered(blackspotId ? blackspotId : previousBlackspotId, !!blackspotId);
        }),
      ),
    { dispatch: false },
  );

  public setHoveredIslandId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setHoveredIslandId),
        tap(({ islandId, hoveredIslandId }) => this.setHovered(islandId, !!hoveredIslandId)),
      ),
    { dispatch: false },
  );

  public setHoveredWatershedId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setHoveredWatershedId),
        tap(({ watershedId, hoveredWatershedId }) => this.setHovered(watershedId, !!hoveredWatershedId)),
      ),
    { dispatch: false },
  );

  public setHoveredCatchmentId$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setHoveredCatchmentId),
        tap(({ catchmentId, hoveredCatchmentId, catchmentName }) => this.setHovered(catchmentId, !!hoveredCatchmentId, catchmentName)),
      ),
    { dispatch: false },
  );

  public setHoveredStationCode$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setHoveredStationCode),
        withLatestFrom(this.store.select(FromWatershed.selectStationCode)),
        tap(([{ stationCode, hoveredStationCode }, stateStationCode]) => {
          if (!stateStationCode) this.setHovered(stationCode, !!hoveredStationCode);
        }),
      ),
    { dispatch: false },
  );

  public setClicked$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatershedActions.setClicked),
        tap(({ clickedElementId }) => {
          if (!!clickedElementId) this.setClicked(clickedElementId);
        }),
      ),
    { dispatch: false },
  );

  public loadWMSLayerLastUpdate$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadWMSLayerLastUpdate),
      mergeMap(() =>
        this.httpClient.get<WMSLayerLastUpdate[]>(PLUJA_RADAR_URL).pipe(
          map(lastUpdatesLayerWMS => WatershedActions.loadWMSLayerLastUpdateSuccess({ lastUpdatesLayerWMS })),
          catchError(() => of(WatershedActions.loadWMSLayerLastUpdateFailure())),
        ),
      ),
    ),
  );

  public loadAnyCatchmentBlackspots$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(WatershedActions.loadAnyCatchmentBlackspots),
      mergeMap(params =>
        this.legacyService.legacyControllerGetAnyCatchmentBlackSpots(params).pipe(
          map(data => WatershedActions.loadAnyCatchmentBlackspotsSuccess({ catchmentId: params.catchmentId, data })),
          catchError(() => of(WatershedActions.loadAnyCatchmentBlackspotsFailure())),
        ),
      ),
    ),
  );

  constructor(
    private readonly httpClient: HttpClient,
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly legacyService: LegacyService,
    private readonly modalRoutingService: ModalRoutingService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private setHovered(id: number | string, hovered: boolean, name?: string): void {
    const elementIdWithName = id?.toString() + "-" + name;
    const element: HTMLElement = name ? this.document.getElementById(elementIdWithName): this.document.getElementById(id?.toString());
    if (element) {
      if (hovered) {
        element.parentElement.style.zIndex = "99999";
        element.classList.add("--hovered");
      } else {
        element.parentElement.style.zIndex = "initial";
        element.classList.remove("--hovered");
      }
    }
  }

  private setClicked(clickedElementId: string): void {
    const element: HTMLElement = this.document.getElementById(clickedElementId);
    if (element) {
      element.click();
    }
  }
}
