import { ActionReducerMap } from "@ngrx/store";
import { AppState, initialState as app } from "./app/state";
import { appReducer } from "./app/reducer";
import { AppEffects } from "./app/effects";
import { UserState, initialState as user } from "./user/state";
import { userReducer } from "./user/reducer";
import { UserEffects } from "./user/effects";
import { WatershedState, initialState as watershed } from "./watershed/state";
import { watershedReducer } from "./watershed/reducer";
import { WatershedEffects } from "./watershed/effects";

export interface RootState {
  app: AppState;
  user: UserState;
  watershed: WatershedState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  user: userReducer,
  watershed: watershedReducer,
};

export const initialRootState: RootState = {
  app,
  user,
  watershed,
};

export const effects = [AppEffects, UserEffects, WatershedEffects];
