/* eslint-disable max-len */
import * as Leaflet from "leaflet";
import { FormStyle, TranslationWidth, getLocaleMonthNames, registerLocaleData } from "@angular/common";
import localeEN from "@angular/common/locales/en";
import localeES from "@angular/common/locales/es";
import localeCA from "@angular/common/locales/ca";
import { BaseMapLayer, MapLayerWMS } from "../types";
import { AppRoutes, BaseMapLayers, MapLayersType, MapLayersWMS } from "../enums";

registerLocaleData(localeEN);
registerLocaleData(localeES);
registerLocaleData(localeCA);

export const APP_NAME = "riscbal-visor-app";

export const BASE_MAP_LAYERS: BaseMapLayer = {
  OpenStreetMaps: {
    name: BaseMapLayers.OpenStreetMaps,
    wmsConfig: {
      baseUrl: `${window.location.protocol}//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
      options: {
        maxZoom: 19,
        minZoom: 6,
        attribution: "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
      },
    },
  },
  BiN: {
    name: BaseMapLayers.BiN,
    wmsConfig: {
      baseUrl: `${window.location.protocol}//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png`,
      options: {
        maxZoom: 19,
        minZoom: 6,
        subdomains: ["server", "services"],
        attribution: "USGS, NOAA",
      },
    },
  },
  Ideib: {
    name: BaseMapLayers.Ideib,
    wmsConfig: {
      baseUrl: "https://ideib.caib.es/geoserveis/services/public/GOIB_MapaBase_IB/MapServer/WMSServer?",
      options: {
        maxZoom: 19,
        layers: "0",
      },
    },
  },
  Ortofoto: {
    name: BaseMapLayers.Ortofoto,
    wmsConfig: {
      baseUrl: "https://ideib.caib.es/geoserveis/services/imatges/GOIB_Ortofoto_2021_IB/MapServer/WMSServer?",
      options: {
        maxZoom: 19,
        layers: "0",
      },
    },
  },
};

export const MAP_LAYERS_WMS: MapLayerWMS = {
  None: {
    name: MapLayersWMS.None,
    time: null,
    type: MapLayersType.None,
    baseUrl: "",
    options: null,
  },
  Water1h: {
    type: MapLayersType.Water,
    name: MapLayersWMS.Water1h,
    time: "1h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:runoff_lasthour",
      transparent: true,
      format: "image/png",
      styles: "BB_1h",
    },
  },
  Water3h: {
    type: MapLayersType.Water,
    name: MapLayersWMS.Water1h,
    time: "3h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:runoff_lasthour",
      transparent: true,
      format: "image/png",
      styles: "BB_1h",
    },
  },
  Water6h: {
    type: MapLayersType.Water,
    name: MapLayersWMS.Water1h,
    time: "6h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:runoff_lasthour",
      transparent: true,
      format: "image/png",
      styles: "BB_1h",
    },
  },
  Water12h: {
    type: MapLayersType.Water,
    name: MapLayersWMS.Water12h,
    time: "12h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:runoff_lasthour",
      transparent: true,
      format: "image/png",
      styles: "BB_24h",
    },
  },
  Water24h: {
    type: MapLayersType.Water,
    name: MapLayersWMS.Water24h,
    time: "24h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:runoff_lastday",
      transparent: true,
      format: "image/png",
      styles: "BB_24h",
    },
  },
  Precipitation1h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation1h,
    time: "1h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_1",
      transparent: true,
      format: "image/png",
      styles: "AEMET_1h_intervals",
    },
  },
  Precipitation3h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation3h,
    time: "3h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_3",
      transparent: true,
      format: "image/png",
      styles: "AEMET_24h_intervals",
    },
  },
  Precipitation6h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation6h,
    time: "6h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_6",
      transparent: true,
      format: "image/png",
      styles: "AEMET_24h_intervals",
    },
  },
  Precipitation12h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation12h,
    time: "12h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_12",
      transparent: true,
      format: "image/png",
      styles: "AEMET_24h_intervals",
    },
  },
  Precipitation24h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation24h,
    time: "24h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_24",
      transparent: true,
      format: "image/png",
      styles: "AEMET_24h_intervals",
    },
  },
  Precipitation48h: {
    type: MapLayersType.Precipitation,
    name: MapLayersWMS.Precipitation48h,
    time: "48h",
    baseUrl: "https://rscbalgeoserver.uib.es/geoserver/wms?",
    options: {
      maxZoom: 19,
      layers: "visor_emergencies:acc_radar_48",
      transparent: true,
      format: "image/png",
      styles: "AEMET_48h_intervals",
    },
  },
  RiversZoomIn: {
    type: MapLayersType.Rivers,
    name: MapLayersWMS.RiversZoomIn,
    baseUrl: "https://rscbalgeoserver.uib.cat/geoserver/visor_public/wms",
    options: {
      maxZoom: 19,
      layers: "visor:rivers_insular",
      transparent: true,
      format: "image/png",
    },
  },
  RiversZoomOut: {
    type: MapLayersType.Rivers,
    name: MapLayersWMS.RiversZoomOut,
    baseUrl: "https://rscbalgeoserver.uib.cat/geoserver/visor_public/wms",
    options: {
      maxZoom: 19,
      layers: "visor:rivers_regional",
      transparent: true,
      format: "image/png",
    },
  },
};

export const PLUJA_RADAR_URL = "https://rscbalgeoserver.uib.es/pluja_radar";

export const MOBILE_MAX_WIDTH = 768;

export const DEFAULT_LANGUAGE = "ca";

export const AVAILABLE_LANGUAGES = ["en", "es", "ca"];

export const INTRODUCTION_ROUTES: AppRoutes[] = [AppRoutes.welcome, AppRoutes.login];

export const NO_INTERCEPTOR_ROUTES: AppRoutes[] = [AppRoutes.login];

export const NO_INTERCEPTOR_REQUESTS: string[] = ["refresh"];

export const MAP_OPTIONS: Leaflet.MapOptions = {
  maxZoom: 19,
  zoom: 9,
  center: { lat: 39.6, lng: 2.9 },
};

export const CLUSTER_OPTIONS: Leaflet.MarkerClusterGroupOptions = {
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: false,
  removeOutsideVisibleBounds: true,
  disableClusteringAtZoom: 14,
  iconCreateFunction: function(cluster): Leaflet.DivIcon {
    let maxIcon: Leaflet.DivIcon;
    const markerArray = cluster.getAllChildMarkers();
    for (let m in markerArray) {
      if (m) {
        const marker = markerArray[m];
        const icon = marker.getIcon();
        if (!maxIcon || Number(icon.options.className) > Number(maxIcon.options.className)) maxIcon = icon;
      }
    }
    return maxIcon;
  },
};

export const monthNames: { [key in string]: readonly string[] } = {
  en: getLocaleMonthNames("en", FormStyle.Format, TranslationWidth.Wide),
  es: getLocaleMonthNames("es", FormStyle.Format, TranslationWidth.Wide),
  ca: getLocaleMonthNames("ca", FormStyle.Format, TranslationWidth.Wide),
};

export const REFRESH_TIME_SECONDS = 60;

export const GROUPPED_CATCHMENTS = [113, 126, 114, 115, 109, 110, 121, 129, 103, 107, 76, 104, 122, 117, 96, 111, 128, 84, 105, 108, 125, 64, 116, 124, 118, 123, 127, 112, 119, 130, 299, 300];
