<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
            icon="arrow-back-outline"
            [text]="'WATERSHED_PAGE.TITLE' | translate"
            (click)="closeWatershed()"
            ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <app-hidrometeo-selector/>
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="watershed">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="watershed">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="(watershed$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div
        class="watershed__description ion-padding --p1"
        [innerHTML]="'WATERSHED_PAGE.DESCRIPTION' | translate: { watershed: (watershed$ | async)?.name }"
    ></div>

    <ion-list>
        @if ((dataLayerSelected$ | async) === dataLayer.WaterLevel) {
            @for (watershedCatchment of watershedCatchments$ | async | orderByAlertLevel; track watershedCatchment.id) {
                <app-data-card
                [description]="'WATER_LEVEL' | translate"
                [hovered]="(hoveredCatchmentId$ | async) === watershedCatchment.id"
                [status]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershedCatchment.status : 0)"
                [statusPredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershedCatchment.statusPredicted : 0)"
                [subtitle]="setCatchmentTitle(watershedCatchment)"
                [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershedCatchment.waterLevel : watershedCatchment.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                [dischargePredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? (watershedCatchment.dischargePredicted | parseValueStations:(dataLayer.Discharge)) : null)"
                (click)="selectCatchment(watershedCatchment.id)"
                (mouseenter)="onMouseEnter(watershedCatchment.id)"
                (mouseleave)="onMouseLeave(watershedCatchment.id)"
                [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        } @else if ((dataLayerSelected$ | async) === dataLayer.Precipitation1h) {
            @for (watershedCatchment of watershedCatchments$ | async | orderByPrecipitation; track watershedCatchment.id) {
                <app-data-card
                [description]="'PRECIPITATION' | translate"
                [hovered]="(hoveredCatchmentId$ | async) === watershedCatchment.id"
                [status]=""
                [subtitle]="setCatchmentTitle(watershedCatchment)"
                [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershedCatchment.waterLevel : watershedCatchment.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                (click)="selectCatchment(watershedCatchment.id)"
                (mouseenter)="onMouseEnter(watershedCatchment.id)"
                (mouseleave)="onMouseLeave(watershedCatchment.id)"
                [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        }
    </ion-list>
</ion-content>