import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IntroductionImageComponent } from "./introduction-image.component";

@NgModule({
  declarations: [IntroductionImageComponent],
  exports: [IntroductionImageComponent],
  imports: [CommonModule],
})
export class IntroductionImageModule {}
