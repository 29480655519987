import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject, filter, takeUntil, tap, withLatestFrom } from "rxjs";
import { CatchmentDto, DataPointDto, StationDataDto, StationDto } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-station",
  templateUrl: "./station.component.html",
  styleUrls: ["./station.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationComponent {
  public readonly stationCode$: Observable<string> = this.store.select(FromWatershed.selectStationCode);

  public readonly station$: Observable<StationDto> = this.store.select(FromWatershed.selectStation);
  public readonly stationData$: Observable<StationDataDto> = this.store.select(FromWatershed.selectStationData);
  public readonly catchment$: Observable<CatchmentDto> = this.store.select(FromWatershed.selectWatershedCatchment);

  public readonly hasTemperatureData$: Observable<boolean> = this.store.select(FromWatershed.selectHasStationDataTemperature);
  public readonly hasWindData$: Observable<boolean> = this.store.select(FromWatershed.selectHasStationDataWind);
  public readonly hasHumidityData$: Observable<boolean> = this.store.select(FromWatershed.selectHasStationDataHumidity);

  public readonly emptyTemperatureData$: Observable<boolean> = this.store.select(FromWatershed.selectEmptyStationDataTemperature);
  public readonly emptyWindData$: Observable<boolean> = this.store.select(FromWatershed.selectEmptyStationDataWind);
  public readonly emptyHumidityData$: Observable<boolean> = this.store.select(FromWatershed.selectEmptyStationDataHumidity);

  public readonly temperature$: Observable<number> = this.store.select(FromWatershed.selectStationDataTemperature);
  public readonly wind$: Observable<number> = this.store.select(FromWatershed.selectStationDataWind);
  public readonly humidity$: Observable<number> = this.store.select(FromWatershed.selectStationDataHumidity);

  public readonly temperatureHours$: Observable<DataPointDto[]> = this.store.select(FromWatershed.selectStationDataTemperatureHours);
  public readonly windHours$: Observable<DataPointDto[]> = this.store.select(FromWatershed.selectStationDataWindHours);
  public readonly humidityHours$: Observable<DataPointDto[]> = this.store.select(FromWatershed.selectStationDataHumidityHours);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  private unsubscribeSubject$: Subject<void>;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public ionViewWillEnter(): void {
    this.unsubscribeSubject$ = new Subject();
    this.stationCode$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(stationCode => !!stationCode),
        tap(stationCode => {
          this.store.dispatch(WatershedActions.loadStationData({ stationCode }));
        }),
      )
      .subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(refreshState => !!refreshState),
        withLatestFrom(this.stationCode$),
        filter(([, stationCode]) => !!stationCode),
        tap(([, stationCode]) => {
          this.store.dispatch(WatershedActions.loadStationData({ stationCode }));
        }),
      )
      .subscribe();
  }

  public ionViewWillLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public resetToIslandsView(): void {
    this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
    this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
    this.store.dispatch(WatershedActions.resetToIslandsView());
  }

  public async closeStation(): Promise<void> {
    this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
  }
}
