import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { WatershedComponent } from "./watershed.component";
import { TranslateModule } from "@ngx-translate/core";
import { DataCardModule } from "src/app/components/data-card/data-card.module";
import { SuppressScrollEventModule } from "src/app/directives/suppress-scroll-event/suppress-scroll-event.module";
import { ShareUrlModule } from "src/app/directives/share-url/share-url.module";
import { ParseValueStationsPipeModule } from "src/app/pipes/parse-value-stations/parse-value-stations.module";
import { OrderStationsByPrecipitationModule } from "../../pipes/order-stations-by-precipitation/order-stations-by-precipitation.module";
import { OrderByPrecipitationModule } from "../../pipes/order-by-precipitation/order-by-precipitation.module";
import { HidroMeteoSelectorModule } from "../../components/hidrometeo-selector/hidrometeo-selector.module";
import { OrderByAlertLevelModule } from "src/app/pipes/order-by-alert-level/order-by-alert-level.module";

@NgModule({
  declarations: [WatershedComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    DataCardModule,
    OrderByAlertLevelModule,
    SuppressScrollEventModule,
    ShareUrlModule,
    ParseValueStationsPipeModule,
    OrderStationsByPrecipitationModule,
    OrderByPrecipitationModule,
    HidroMeteoSelectorModule,
  ],
})
export class WatershedModule {}
