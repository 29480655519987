/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_NAME, PLUJA_RADAR_URL } from "src/app/constants";
import { environment } from "src/environments/environment";

const noAuthUrls = [PLUJA_RADAR_URL];

@Injectable({ providedIn: "root" })
export class VersionInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!noAuthUrls.includes(req.url)) {
      req = req.clone({
        setHeaders: {
          "X-APP-RELEASE": environment.release,
          "X-APP-ORIGIN": APP_NAME,
        },
      });
    }

    return next.handle(req);
  }
}
