import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DatetimeComponent } from "./datetime.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DatetimeComponent],
  exports: [DatetimeComponent],
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule],
})
export class DatetimeModule {}
