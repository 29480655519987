/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import { Action, ActionReducer, INIT } from "@ngrx/store";
import { initialRootState } from "..";
import * as UserActions from "./actions";

export const logoutMetaReducer =
  <State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> =>
  (state: State, action: Action): State => {
    if (action.type === UserActions.logout.type) {
      return reducer(initialRootState as any, { type: INIT });
    } else {
      return reducer(state, action);
    }
  };
