<div class="introduction-image">
    <div class="introduction-image__gradient">
        <img src="assets/images/welcome-logo.png" />
    </div>

    <div class="introduction-image__logos">
        <div class="introduction-image__logos__c2impress">
            <img class="introduction-image__logos__c2impress__ue-flag" src="assets/images/ue-flag.png" />
            <div class="introduction-image__logos__c2impress__text">
                <p class="introduction-image__logos__c2impress__text__title">
                    <b>C2IMPRESS</b><br />Grant agreement ID:<br />101074004
                </p>
            </div>
        </div>

        <div class="introduction-image__logos__uib">
            <img class="introduction-image__logos__uib__icon" src="assets/logos/uib-lletra-blanca.jpg" />
        </div>
    
        <div class="introduction-image__logos__govern">
            <img class="introduction-image__logos__govern__icon" src="assets/logos/govern-blanc.png" />
        </div>
    </div>
</div>
