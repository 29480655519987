/* eslint-disable @typescript-eslint/no-explicit-any */
import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataLayer } from "src/app/enums";

@Pipe({ name: "parseValueStations" })
export class ParseValueStationsPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe,
  ) {}

  public transform(value: number, typeOfData: DataLayer): string {
    if (value === undefined || value === null) return "-";
    switch (typeOfData) {
      case DataLayer.WaterLevel:
        return this.translateService.instant("WATER_LEVEL_UNIT", {
          value: this.decimalPipe.transform(Math.max(value || 0, 0) / 100, "1.0-2", this.translateService.defaultLang),
        });
        break;
      case DataLayer.Precipitation1h:
        return this.translateService.instant("PRECIPITATION_UNIT", {
          value: this.decimalPipe.transform(Math.max(value || 0, 0), "1.0-2", this.translateService.defaultLang),
        });
        break;
      case DataLayer.Discharge:
        return this.translateService.instant("DISCHARGE_UNIT", {
          value: this.decimalPipe.transform(Math.max(value || 0, 0), "1.0-2", this.translateService.defaultLang),
        });
        break;
      default:
        return "No recognized type";
    }
  }
}
