import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { ToMarkerPipe } from "./to-marker.pipe";
import { ParseValueStationsPipe } from "../parse-value-stations/parse-value-stations.pipe";

@NgModule({
  declarations: [ToMarkerPipe],
  imports: [CommonModule],
  exports: [ToMarkerPipe],
  providers: [DecimalPipe, ParseValueStationsPipe],
})
export class ToMarkerModule {}
