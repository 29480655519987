import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParseToStationCardItemsPipe } from "./parse-to-station-card-items.pipe";

@NgModule({
  declarations: [ParseToStationCardItemsPipe],
  imports: [CommonModule],
  exports: [ParseToStationCardItemsPipe],
})
export class ParseToStationCardItemsModule {}
