import { Directive, ElementRef, OnDestroy, OnInit, Renderer2, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";

@Directive({ selector: "[appInputErrors]" })
export class InputErrorsDirective implements OnInit, OnDestroy {
  private destroyed: Subject<void>;

  constructor(
    @Self() private readonly control: NgControl,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly translate: TranslateService,
  ) {
    this.destroyed = new Subject();
  }

  public ngOnInit(): void {
    this.control.valueChanges?.pipe(takeUntil(this.destroyed)).subscribe(() => {
      setTimeout(() => {
        const controlErrors = this.control.errors;

        if (controlErrors) {
          const key = Object.keys(controlErrors)[0];

          switch (key) {
            case "minlength":
              const { actualLength: minActualLength, requiredLength: minRequiredLength } = controlErrors["minlength"];
              this.setErrorMessage(
                this.translate.instant("FORM_ERROR_MIN_LENGTH", { actual: minActualLength, required: minRequiredLength }),
              );
              break;
            case "maxlength":
              const { actualLength: maxActualLength, requiredLength: maxRequiredLength } = controlErrors["maxlength"];
              this.setErrorMessage(
                this.translate.instant("FORM_ERROR_MAX_LENGTH", { actual: maxActualLength, required: maxRequiredLength }),
              );
              break;
            case "email":
              this.setErrorMessage(this.translate.instant("FORM_ERROR_EMAIL"));
              break;
            default:
              this.setErrorMessage(
                (this.el.nativeElement?.placeholder ? `${this.el.nativeElement?.placeholder} ` : "") +
                  this.translate.instant("FORM_ERROR_REQUIRED"),
              );
              break;
          }
        } else {
          this.renderer.removeAttribute(this.el.nativeElement, "error-message");
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.complete();
  }

  private setErrorMessage(message: string): void {
    this.renderer.setAttribute(this.el.nativeElement, "error-message", message);
  }
}
