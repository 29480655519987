import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputErrorsDirective } from "./input-errors.directive";

@NgModule({
  declarations: [InputErrorsDirective],
  imports: [CommonModule],
  exports: [InputErrorsDirective],
})
export class InputErrorsModule {}
