import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ModalWidth } from "src/app/types";
import { Store } from "@ngrx/store";
import { DataLayer } from "src/app/enums";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-data-card",
  templateUrl: "./data-card.component.html",
  styleUrls: ["./data-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCardComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public description: string;
  @Input() public hovered: boolean;
  @Input() public status?: number;
  @Input() public statusPredicted?: number;
  @Input() public discharge?: string;
  @Input() public dischargePredicted?: string;
  @Input() public dataLayerSelected?: DataLayer;

  public dataLayer: typeof DataLayer = DataLayer;
}
