/* eslint-disable @typescript-eslint/no-explicit-any */
import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Item } from "src/app/components/station-card/station-card.component";
import { StationDto } from "src/app/services/api";

@Pipe({ name: "parseToStationCardItems" })
export class ParseToStationCardItemsPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe,
  ) {}

  public transform(station: StationDto, type: "hydro" | "meteo"): Item[] {
    const items: Item[] = [];

    if (type === "hydro") {
      if (station.alertLevel !== null && station.alertLevel !== undefined) {
        items.push({
          title: this.translateService.instant("ALERT_UNIT", { value: Math.max(station.alertLevel, 0) }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.ALERT_LEVEL"),
        });
      }

      if (station.waterLevel !== null && station.waterLevel !== undefined) {
        items.push({
          title: this.translateService.instant("WATER_LEVEL_UNIT", {
            value: this.decimalPipe.transform(Math.max(station.waterLevel, 0) / 100, "1.0-2", this.translateService.defaultLang),
          }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.WATER_LEVEL"),
        });
      }
    }

    if (type === "meteo") {
      if (station.precipitationLast1h !== null && station.precipitationLast1h !== undefined) {
        items.push({
          title: this.translateService.instant("PRECIPITATION_UNIT", {
            value: this.decimalPipe.transform(station.precipitationLast1h, "1.0-2", this.translateService.defaultLang),
          }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.LAST_HOUR"),
        });
      }

      if (station.precipitationLast24h !== null && station.precipitationLast24h !== undefined) {
        items.push({
          title: this.translateService.instant("PRECIPITATION_UNIT", {
            value: this.decimalPipe.transform(station.precipitationLast24h, "1.0-2", this.translateService.defaultLang),
          }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.LAST_24_HOUR"),
        });
      }

      if (station.intensity !== null && station.intensity !== undefined) {
        items.push({
          title: this.translateService.instant("INTENSITY_UNIT", {
            value: this.decimalPipe.transform(station.intensity, "1.0-2", this.translateService.defaultLang),
          }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.INTENSITY"),
        });
      }

      if (station.humidity !== null && station.humidity !== undefined) {
        if (station.owner == "0") {
          items.push({
            title: this.translateService.instant("HUMIDITY_UNIT", {
              value: this.decimalPipe.transform(station.humidity, "1.0-2", this.translateService.defaultLang),
            }),
            subtitle: this.translateService.instant("CATCHMENT_PAGE.HUMIDITY"),
          });
        }
      }

      if (station.temperature !== null && station.temperature !== undefined) {
        items.push({
          title: this.translateService.instant("TEMPERATURE_UNIT", {
            value: this.decimalPipe.transform(station.temperature, "1.0-2", this.translateService.defaultLang),
          }),
          subtitle: this.translateService.instant("CATCHMENT_PAGE.TEMPERATURE"),
        });
      }
    }

    return items;
  }
}
