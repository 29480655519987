import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-context-toast",
  templateUrl: "./context-toast.component.html",
  styleUrls: ["./context-toast.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextToastComponent {
  @Input() public status: "error" | "warning" | "success";
  @Input() public text: string;
  @Input() public iconName: string;
}
