import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { Observable, Subject, filter, takeUntil, tap, withLatestFrom } from "rxjs";
import { IslandDto } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";
import { DataLayer } from "src/app/enums";

@Component({
  selector: "app-islands",
  templateUrl: "./islands.component.html",
  styleUrls: ["./islands.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IslandsComponent {
  public dataLayer: typeof DataLayer = DataLayer;
  public readonly hoveredIslandId$: Observable<string> = this.store.select(FromWatershed.selectHoveredIslandId);
  public readonly islands$: Observable<IslandDto[]> = this.store.select(FromWatershed.selectIslands);
  public readonly islandId$: Observable<string> = this.store.select(FromWatershed.selectIslandId);
  public readonly dataLayer$: Observable<DataLayer> = this.store.select(FromWatershed.selectDataLayer);
  public readonly dataLayerSelected$: Observable<DataLayer> = this.store.select(FromWatershed.selectDataLayer);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  private unsubscribeSubject$: Subject<void>;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public ionViewWillEnter(): void {
    this.unsubscribeSubject$ = new Subject();

    this.islandId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(islandId => !!islandId),
        tap(islandId => {
          if (!islandId) this.store.dispatch(WatershedActions.loadIslands());
        }),
      ).subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        withLatestFrom(this.islandId$),
        filter((refreshState) => !!refreshState),
        tap(([, islandId]) => {
          if (!islandId) this.store.dispatch(WatershedActions.loadIslands());
        }),
      ).subscribe();
  }

  public ionViewWillLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public selectDataToShow(dataLayer: DataLayer): void {
    this.store.dispatch(WatershedActions.setDataLayer({ dataLayer }));
  }

  public selectIsland(islandId: string | number): void {
    this.store.dispatch(WatershedActions.setIslandId({ islandId: islandId.toString() }));
  }

  public onMouseEnter(islandId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredIslandId({ islandId: islandId.toString(), hoveredIslandId: islandId.toString() }));
  }

  public onMouseLeave(islandId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredIslandId({ islandId: islandId.toString(), hoveredIslandId: null }));
  }
}
