export enum AppRoutes {
  welcome = "welcome",
  login = "login",
  map = "map",
}

export enum ModalIds {
  MapModal = "map-modal",
  ProfileModal = "profile-modal",
}

export enum MapLayers {
  OpenStreetMaps = "OpenStreetMaps",
  BiN = "BiN",
}

export enum BaseMapLayers {
  OpenStreetMaps = "OpenStreetMaps",
  BiN = "BiN",
  Ideib = "Ideib",
  Ortofoto = "Ortofoto"
}

export enum MapLayersType {
  None = "None",
  Water = "Water",
  Precipitation = "Precipitation",
  Rivers = "Rivers",
}

export enum MapLayersWMS {
  None = "None",
  Water1h = "Water1h",
  Water3h = "Water3h",
  Water6h = "Water6h",
  Water12h = "Water12h",
  Water24h = "Water24h",
  Precipitation1h = "Precipitation1h",
  Precipitation3h = "Precipitation3h",
  Precipitation6h = "Precipitation6h",
  Precipitation12h = "Precipitation12h",
  Precipitation24h = "Precipitation24h",
  Precipitation48h = "Precipitation48h",
  RiversZoomIn = "RiversZoomIn",
  RiversZoomOut = "RiversZoomOut",
}

export enum DataLayer {
  WaterLevel = "WaterLevel",
  Precipitation1h = "Precipitation1h",
  Discharge = "Discharge"
}

export enum IslandsNames {
  Pitiuses = "Illes Pitiüses",
  Mallorca = "Mallorca",
  Menorca = "Menorca"
}
