import { StationDto, BlackSpotDto, IslandDto, WatershedDto, CatchmentDto } from "src/app/services/api";

export function svgIcon(element: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto): string {
  let svg_icon = "";

  // BlackSpotDto
  if ("catchmentId" in element && !("associatedCatchmentIds" in element)) {
    switch (element.type){
      case "0":
        // PONT RECTE
        svg_icon = `<svg class="map-icon --is-blackspot --is-${element.status}" version="1.0" viewBox="0 0 500.000000 500.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" stroke="none">
        <path class="icon-fill" fill="currentColor" stroke="#000000" stroke-width="200"
          d="M920 2500 l0 -750 284 0 283 0 7 78 c16 181 91 380 197 523 358 483 1064 548 1502 139 189 -177 296 -398 323 -667 
          l7 -73 278 0 279 0 0 750 0 750 -1580 0 -1580 0 0 -750z"/></g></svg>`;
        break;

      case "1":
        // PAS A NIVELL
        svg_icon = `
        <svg class="map-icon --is-blackspot --is-${element.status}" version="1.0"
          viewBox="0 0 500.000000 500.000000" preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" stroke="none">
            <path class="icon-fill" fill="currentColor" stroke="#000000" stroke-width="200" 
            d="M570 2500 l0 -710 1930 0 1930 0 0 710 0 710 -259 0 -260 0 -26 -57
            c-101 -223 -311 -466 -523 -607 -498 -332 -1123 -353 -1630 -56 -260 154 -488
            402 -609 666 l-24 54 -265 0 -264 0 0 -710z"/>
          </g>
        </svg>
        `;
        break;
    }
  } else if ("catchmentId" in element) {
    switch (element.type) {
      case "1":
        // SVG icon from src\assets\images\circle-fill-white.svg
        svg_icon = `
            <svg class="map-icon --is-${element.status}" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> 
                <g class="layer">
                <title>circle</title>
                <ellipse class="icon-fill" fill="currentColor" stroke="currentColor" 
                    cx="256" cy="255" rx="215" ry="215" />
                <path  class="icon-border" fill="currentColor" stroke="currentColor" transform="matrix(1, 0, 0, 1, 0, 0)" 
                    d="m464,256a208,208 0 1 0 -416,0a208,208 0 1 0 416,0zm-464,0a256,256 0 1 1 512,0a256,256 0 1 1 -512,0z"/>
                </g>
            </svg>
            `;
        break;
      case "2":
        if (!element.waterLevel) {
          // Hidrometeo station without hidro information (so it looks/behaves as meteo station)
          // SVG icon from src\assets\images\triangle-fill-white.svg
          svg_icon = `
          <svg class="map-icon --is-${element.status}" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
              <g class="layer">
              <title>triangle</title>
                  <path class="icon-fill" fill="currentColor" stroke="currentColor" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-opacity="null" 
                  d="m253.64,22.9l-229.67,390.51l463.38,0l-233.71,-390.51z" />
                  <path class="icon-border" fill="#000" stroke="#000" transform="matrix(1, 0, 0, 1, 0, 0)"
                  d="m245.78,53.92c4.25,-6.8 14.23,-6.8 18.27,0l199.08,330.38c4.25,7.01 -0.85,15.93 -9.14,15.93l-396.67,0c-8.07,0 -13.17,-8.92 -8.92,-16.15l197.38,-330.17l0,0.01zm9.14,-52.69c-16.15,0 -32.29,7.86 -41.64,23.58l-206.3,343.76c-19.33,32.29 3.82,73.72 41.64,73.72l412.6,0c37.82,0 61.19,-41.43 41.86,-73.72l-206.3,-343.76c-9.56,-15.72 -25.71,-23.58 -41.86,-23.58z"/>
              </g>
              </g>
          </svg>
          `;
        } else {
          // SVG icon from src\assets\images\circle-fill-white.svg
          svg_icon = `
          <svg class="map-icon --is-${element.status}" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> 
              <g class="layer">
              <title>circle</title>
              <ellipse class="icon-fill" fill="currentColor" stroke="currentColor" 
                  cx="256" cy="255" rx="215" ry="215" />
              <path  class="icon-border" fill="currentColor" stroke="currentColor" transform="matrix(1, 0, 0, 1, 0, 0)" 
                  d="m464,256a208,208 0 1 0 -416,0a208,208 0 1 0 416,0zm-464,0a256,256 0 1 1 512,0a256,256 0 1 1 -512,0z"/>
              </g>
          </svg>
          `;
        }
        break;
      case "0":
      default:
        // SVG icon from src\assets\images\triangle-fill-white.svg
        svg_icon = `
        <svg class="map-icon --is-${element.status}" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
            <g class="layer">
            <title>triangle</title>
                <path class="icon-fill" fill="currentColor" stroke="currentColor" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-opacity="null" 
                d="m253.64,22.9l-229.67,390.51l463.38,0l-233.71,-390.51z" />
                <path class="icon-border" fill="#000" stroke="#000" transform="matrix(1, 0, 0, 1, 0, 0)"
                d="m245.78,53.92c4.25,-6.8 14.23,-6.8 18.27,0l199.08,330.38c4.25,7.01 -0.85,15.93 -9.14,15.93l-396.67,0c-8.07,0 -13.17,-8.92 -8.92,-16.15l197.38,-330.17l0,0.01zm9.14,-52.69c-16.15,0 -32.29,7.86 -41.64,23.58l-206.3,343.76c-19.33,32.29 3.82,73.72 41.64,73.72l412.6,0c37.82,0 61.19,-41.43 41.86,-73.72l-206.3,-343.76c-9.56,-15.72 -25.71,-23.58 -41.86,-23.58z"/>
            </g>
            </g>
        </svg>
        `;
        break;
    };
  };
  return svg_icon;
}
