/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { StationDto } from "src/app/services/api";

@Pipe({ name: "orderStationsByPrecipitation" })
export class OrderStationsByPrecipitationPipe implements PipeTransform {
  public transform(elements: StationDto[]): StationDto[] {
    if (!elements) return [];
    return ([...elements] || []).sort((a, b) => {
      if (!this.isNumber(a.precipitationLast1h) && !this.isNumber(b.precipitationLast1h)) return a.id.localeCompare(b.id);
      else if (!this.isNumber(a.precipitationLast1h)) return 1;
      else if (!this.isNumber(b.precipitationLast1h)) return -1;
      const result = b.precipitationLast1h - a.precipitationLast1h;
      return result ? result : a.id.localeCompare(b.id);
    });
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }
}
