import { createAction, props } from "@ngrx/store";
import { Geometry } from "geojson";
import {
  AuthenticationControllerLoginRequestParams,
  AuthenticationControllerUpdateUserSelfRequestParams,
  CatchmentDto,
  LatLng,
  LegacyControllerGetPointLocationRequestParams,
  MapPointDto,
  UserDto,
} from "src/app/services/api";

export const setAccessToken = createAction("[USER] Set Access Token", props<{ accessToken: string }>());
export const setRefreshToken = createAction("[USER] Set Refresh Token", props<{ refreshToken: string }>());

export const setUpdateSuccess = createAction("[USER] Set Update Success", props<{ updateSuccess: boolean }>());

export const resetError = createAction("[USER] Reset Error");

export const login = createAction("[USER] Login", props<AuthenticationControllerLoginRequestParams>());
export const loginSuccess = createAction("[USER] Login Success", props<{ accessToken: string; refreshToken: string }>());
export const loginFailure = createAction("[USER] Login Failure", props<{ error: string }>());

export const guestLogin = createAction("[USER] Login of guest user", props<AuthenticationControllerLoginRequestParams>());

export const logout = createAction("[USER] Logout");

export const loadUser = createAction("[USER] Load User");
export const loadUserSuccess = createAction("[USER] Load User Success", props<{ user: UserDto }>());
export const loadUserFailure = createAction("[USER] Load User Failure");

export const updateUser = createAction("[USER] Update User", props<AuthenticationControllerUpdateUserSelfRequestParams>());
export const updateUserSuccess = createAction("[USER] Update User Success", props<{ user: UserDto }>());
export const updateUserFailure = createAction("[USER] Update User Failure", props<{ error: string }>());

export const loadUserCurrentPosition = createAction(
  "[USER] Load User's Current Position",
  props<LegacyControllerGetPointLocationRequestParams>());
export const loadUserCurrentPositionSuccess = createAction(
  "[USER] Load User's Current Position Success",
  props<{ userPosition: MapPointDto }>());
export const loadUserCurrentPositionFailure = createAction("[USER] Load User's Current Position Failure");

export const setUserLatLng = createAction("[USER] Set User's Position", props<{userLatLng: LatLng}>());
