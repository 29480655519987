import { MapPointDto, UserDto } from "src/app/services/api";

export interface UserState {
  user?: UserDto;
  guest: boolean;
  accessToken?: string;
  refreshToken?: string;
  error?: string;
  getLoading: boolean;
  updateLoading: boolean;
  updateSuccess: boolean;
  userCurrentPosition: MapPointDto;
}

export const initialState: UserState = {
  user: null,
  guest: false,
  accessToken: null,
  refreshToken: null,
  error: null,
  getLoading: false,
  updateLoading: false,
  updateSuccess: false,
  userCurrentPosition: null,
};

export const persistedKeys: (keyof UserState)[] = ["user", "guest", "accessToken", "refreshToken"];
