import { createReducer, on } from "@ngrx/store";
import { UserState, initialState } from "./state";
import * as UserActions from "./actions";
import { NONE_TYPE } from "@angular/compiler";

export const userReducer = createReducer(
  initialState,

  on(UserActions.setAccessToken, (state, { accessToken }): UserState => ({ ...state, accessToken })),

  on(UserActions.setRefreshToken, (state, { refreshToken }): UserState => ({ ...state, refreshToken })),

  on(UserActions.setUpdateSuccess, (state, { updateSuccess }): UserState => ({ ...state, updateSuccess })),

  on(UserActions.resetError, (state): UserState => ({ ...state, error: null })),

  on(UserActions.loadUser, (state): UserState => ({ ...state, getLoading: true })),

  on(UserActions.login, UserActions.updateUser, (state): UserState => ({ ...state, updateLoading: true })),

  on(UserActions.guestLogin, UserActions.updateUser, (state): UserState => ({
    ...state, updateLoading: true, guest: true })),

  on(UserActions.loadUserFailure, (state): UserState => ({ ...state, getLoading: false })),

  on(
    UserActions.loginSuccess,
    (state, { accessToken, refreshToken }): UserState => ({
      ...state,
      accessToken,
      refreshToken,
      error: null,
      updateLoading: false,
      updateSuccess: true,
    }),
  ),

  on(UserActions.loginFailure, UserActions.updateUserFailure, (state, { error }): UserState => ({ ...state, error, updateLoading: false })),

  on(
    UserActions.updateUserSuccess,
    (state, { user }): UserState => ({ ...state, user, error: null, updateLoading: false, updateSuccess: true }),
  ),

  on(UserActions.loadUserSuccess, (state, { user }): UserState => ({ ...state, user: ((state.guest) ? null : user), getLoading: false })),

  on(UserActions.logout, (): UserState => initialState),

  on(
    UserActions.loadUserCurrentPositionSuccess,
    (state, { userPosition }): UserState => ({ ...state, userCurrentPosition: userPosition }),
  ),

  on(
    UserActions.setUserLatLng,
    (state, { userLatLng }): UserState => ({ ...state, userCurrentPosition: { ...state.userCurrentPosition, geometry: userLatLng } }),
  ),
);
