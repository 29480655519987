import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserImageComponent } from "./user-image.component";

@NgModule({
  declarations: [UserImageComponent],
  imports: [CommonModule],
  exports: [UserImageComponent],
})
export class UserImageModule {}
