<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                icon="arrow-back-outline"
                [text]="'WATERSHEDS_PAGE.TITLE' | translate"
                (click)="closeIsland()"
            ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <app-hidrometeo-selector/>
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="watersheds">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="watersheds">
    <ion-header collapse="condense">
        <ion-toolbar class="toolbar">
            <ion-title class="--h1" [innerHTML]="(island$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div
        class="watersheds__description ion-padding --p1"
        [innerHTML]="isPitiuses((island$ | async))
            ? ('WATERSHEDS_PAGE.DESCRIPTION_PITIUSES' | translate: { island: (island$ | async)?.name }) 
            : ('WATERSHEDS_PAGE.DESCRIPTION' | translate: { island: (island$ | async)?.name })"
    ></div>

    <ion-list>
        @if ((dataLayerSelected$ | async) === dataLayer.WaterLevel) {
            @for (watershed of watersheds$ | async | orderByAlertLevel; track watershed.id) {
                <app-data-card
                    [description]="'WATER_LEVEL' | translate"
                    [hovered]="(hoveredWatershedId$ | async) === watershed.id"
                    [status]="watershed.status"
                    [statusPredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershed.statusPredicted : 0)"
                    [subtitle]="watershed.name"
                    [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershed.waterLevel : watershed.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                    [dischargePredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? (watershed.dischargePredicted | parseValueStations:(dataLayer.Discharge)) : null)"
                    (click)="selectWatershed(watershed.id)"
                    (mouseenter)="onMouseEnter(watershed.id)"
                    (mouseleave)="onMouseLeave(watershed.id)"
                    [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        } @else if ((dataLayerSelected$ | async) === dataLayer.Precipitation1h) {
            @for (watershed of watersheds$ | async | orderByPrecipitation; track watershed.id) {
                <app-data-card
                    [description]="'PRECIPITATION' | translate"
                    [hovered]="(hoveredWatershedId$ | async) === watershed.id"
                    [status]=""
                    [subtitle]="watershed.name"
                    [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? watershed.waterLevel : watershed.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                    (click)="selectWatershed(watershed.id)"
                    (mouseenter)="onMouseEnter(watershed.id)"
                    (mouseleave)="onMouseLeave(watershed.id)"
                    [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        }
    </ion-list>
</ion-content>
