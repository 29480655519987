<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <app-hidrometeo-selector/>
            <ion-button [disabled]="true"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button class="share-button" appShareUrl="islands">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="islands">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="'ISLANDS_PAGE.TITLE' | translate"></ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-list>
        @if ((dataLayerSelected$ | async) === dataLayer.WaterLevel) {
            @for (island of islands$ | async | orderByAlertLevel; track island.id) {
                <app-data-card
                    [description]="'WATER_LEVEL' | translate"
                    [hovered]="(hoveredIslandId$ | async) === island.id"
                    [status]="island.status"
                    [statusPredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? island.statusPredicted : 0)"
                    [subtitle]="island.name"
                    [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? island.waterLevel : island.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                    [dischargePredicted]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? (island.dischargePredicted | parseValueStations:(dataLayer.Discharge)) : null)"
                    (click)="selectIsland(island.id)"
                    (mouseenter)="onMouseEnter(island.id)"
                    (mouseleave)="onMouseLeave(island.id)"
                    [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        } @else if ((dataLayerSelected$ | async) === dataLayer.Precipitation1h) {
            @for (island of islands$ | async | orderByPrecipitation; track island.id) {
                <app-data-card
                    [description]="'PRECIPITATION' | translate"
                    [hovered]="(hoveredIslandId$ | async) === island.id"
                    [status]=""
                    [subtitle]="island.name"
                    [title]="((dataLayerSelected$ | async) === dataLayer.WaterLevel ? island.waterLevel : island.precipitationLast1h) | parseValueStations:(dataLayerSelected$ | async)"
                    (click)="selectIsland(island.id)"
                    (mouseenter)="onMouseEnter(island.id)"
                    (mouseleave)="onMouseLeave(island.id)"
                    [dataLayerSelected]="(dataLayerSelected$ | async)"
                ></app-data-card>
            }
        }

    </ion-list>
</ion-content>
