<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button icon="arrow-back-outline" [text]="(catchment$ | async)?.name"
                (click)="closeStation()"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <!-- <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button> -->
            <ion-button appShareUrl="station">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="station">
    <ion-header collapse="condense">
        <ion-toolbar>
            @if ((blackspot$ | async)?.type === "0") {
                <ion-label class="--h1"
                [innerHTML]="('BLACKSPOT_PAGE.BRIDGE' | translate) + ' - ' + ((blackspot$ | async)?.name)"></ion-label>
            }
            @else if ((blackspot$ | async)?.type === "1") { 
                <ion-label class="--h1"
                [innerHTML]="('BLACKSPOT_PAGE.LEVEL_CROSSING' | translate) + ' - ' + ((blackspot$ | async)?.name)"></ion-label>
            }
            @else {
                <ion-label class="--h1"
                [innerHTML]="('BLACKSPOT_PAGE.BLACKSPOT' | translate) + ' - ' + ((blackspot$ | async)?.name)"></ion-label>
            }
            </ion-toolbar>
    </ion-header>

    <div class="station__content">
        
        
        <!-- <p class="station__content__name --p1" [innerHTML]="blackspotId$ | async"></p> -->
        
        <p class="station__content__title --h2" [innerHTML]="'BLACKSPOT_PAGE.PREDICTED_DISCHARGE' | translate"></p>
        <app-chart 
        chartType="line" 
        [alertLevels]="(blackspotData$ | async)?.alertLevels"
        [data]="(blackspotData$ | async)?.discharge"
        [currentTime]="current_timestamp"
        ></app-chart>
    </div>
</ion-content>