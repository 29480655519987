import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of, first, switchMap } from "rxjs";
import { AppRoutes } from "src/app/enums";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly store: Store<RootState>,
    private readonly router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(FromUser.selectAuth).pipe(
      first(),
      switchMap(access => {
        if (access.accessToken){
          return of(true);
        } else if (access.guest){
          return of(true);
        } else {
          return of(this.router.parseUrl(AppRoutes.welcome));
        }
      }),
    );
  }
}
