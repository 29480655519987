import { RootState } from "..";
import { AppState } from "./state";
import { createSelector } from "@ngrx/store";

const selectFeature = (state: RootState): AppState => state.app;

export const selectModalHeight = createSelector(selectFeature, state => state.modalHeight);

export const selectModalWidth = createSelector(selectFeature, state => state.modalWidth);

export const selectRefreshCount = createSelector(selectFeature, state => state.refreshCount);

export const selectRefreshState = createSelector(selectFeature, state => state.refreshState);

export const selectRefreshLastDate = createSelector(selectFeature, state => state.refreshLastDate);
