/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import { Pipe, PipeTransform } from "@angular/core";
import { BlackSpotDto, CatchmentDto, IslandDto, StationDto, WatershedDto } from "src/app/services/api";
import * as Leaflet from "leaflet";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import * as WatershedActions from "src/app/store/watershed/actions";
import { Observable, map } from "rxjs";
import * as FromWatershed from "src/app/store/watershed/selectors";
import { DataLayer } from "src/app/enums";
import { ParseValueStationsPipe } from "../parse-value-stations/parse-value-stations.pipe";
import { svgIcon } from "src/app/utils/svgicon";
import { instanceOfStationDto, instanceOfBlackspotDto, instanceOfCatchmentDto, instanceOfWatershedDto } from "src/app/utils/instanceOf";

@Pipe({ name: "toMarker" })
export class ToMarkerPipe implements PipeTransform {
  constructor(
    private readonly store: Store<RootState>,
    private readonly parseValueStations: ParseValueStationsPipe,
  ) {}

  public transform(element: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto): Observable<Leaflet.Marker<any>> {
    return this.store.select(FromWatershed.selectDataLayer).pipe(
      map(dataLayer =>
        Leaflet.marker(
          { ...element.geometry },
          { icon: Leaflet.divIcon({ html: this.getMapCard(element, dataLayer), className: "dummy" }) },
        )
          .on("click", () => {
            this.dispatchNavigation(element);
          })
          .on("mouseover", () => {
            if (instanceOfBlackspotDto(element)) {
              // crear event especific per blackspot?
              this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: element.id }));
            } else if (instanceOfStationDto(element)) {
              this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: element.id }));
            } else if (instanceOfCatchmentDto(element)) {
              this.store.dispatch(
                WatershedActions.setHoveredCatchmentId({
                  catchmentId: element.id,
                  hoveredCatchmentId: element.id,
                  catchmentName: element.name,
                }),
              );
            } else if (instanceOfWatershedDto(element)) {
              this.store.dispatch(WatershedActions.setHoveredWatershedId({ watershedId: element.id, hoveredWatershedId: element.id }));
            } else {
              this.store.dispatch(WatershedActions.setHoveredIslandId({ islandId: element.id, hoveredIslandId: element.id }));
            }
          })
          .on("mouseout", () => {
            if (instanceOfBlackspotDto(element)) {
              // crear event especific per blackspot?
              this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: null }));
            } else if (instanceOfStationDto(element)) {
              this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: null }));
            } else if (instanceOfCatchmentDto(element)) {
              this.store.dispatch(
                WatershedActions.setHoveredCatchmentId({
                  catchmentId: element.id,
                  hoveredCatchmentId: null,
                  catchmentName: element.name,
                }),
              );
            } else if (instanceOfWatershedDto(element)) {
              this.store.dispatch(WatershedActions.setHoveredWatershedId({ watershedId: element.id, hoveredWatershedId: null }));
            } else {
              this.store.dispatch(WatershedActions.setHoveredIslandId({ islandId: element.id, hoveredIslandId: null }));
            }
          }),
      ),
    );
  }

  private dispatchNavigation(element: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto): void {
    let blackspotId: string;
    let stationCode: string;
    let catchmentId: number;
    let watershedId: number;

    this.store.select(FromWatershed.selectBlackspotId).subscribe(blackspotId => (blackspotId = blackspotId));
    this.store.select(FromWatershed.selectStationCode).subscribe(code => (stationCode = code));
    this.store.select(FromWatershed.selectCatchmentId).subscribe(id => (catchmentId = id));
    this.store.select(FromWatershed.selectWatershedId).subscribe(id => (watershedId = id));

    if (instanceOfBlackspotDto(element)) {
      if (stationCode) this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
      this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: element.id }));
    } else if (instanceOfStationDto(element)) {
      if (blackspotId) this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: null }));
      this.store.dispatch(WatershedActions.setStationCode({ stationCode: element.id }));
    } else if (instanceOfCatchmentDto(element)) {
      if (stationCode) this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
      if (blackspotId) this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: null }));
      if (element.watershedId !== watershedId) this.store.dispatch(WatershedActions.setWatershedId({ watershedId: +element.watershedId }));
      this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: +element.id }));
    } else if (instanceOfWatershedDto(element)) {
      if (catchmentId) this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
      this.store.dispatch(WatershedActions.setWatershedId({ watershedId: +element.id }));
    } else {
      if (blackspotId) this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: null }));
      if (stationCode) this.store.dispatch(WatershedActions.setStationCode({ stationCode: null }));
      if (catchmentId) this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
      if (watershedId) this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
      this.store.dispatch(WatershedActions.setIslandId({ islandId: element.id }));
    }
  }

  public transformBlackspotMarker(element: BlackSpotDto, dataLayer: DataLayer): Leaflet.Marker<any> {
    const dummy = element.status ? String(element.status) : "0";
    let marker: Leaflet.Marker<any> = Leaflet.marker(
      { ...element.geometry },
      { icon: Leaflet.divIcon({ html: this.getMapCard(element, dataLayer), className: dummy }) },
    )
      .on("click", () => {
        if (instanceOfBlackspotDto(element)) {
          this.store.dispatch(WatershedActions.setBlackspotId({ blackspotId: element.id }));
        }
      })
      .on("mouseover", () => {
        if (instanceOfBlackspotDto(element)) {
          this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: element.id }));
        }
      })
      .on("mouseout", () => {
        if (instanceOfBlackspotDto(element)) {
          this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode: element.id, hoveredStationCode: null }));
        }
      });
    return marker;
  }

  public getMapCard(element: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto, dataLayer: DataLayer): string {
    const stationIcon = svgIcon(element);

    if (instanceOfBlackspotDto(element)) {
      const imageIcon = "assets/images/riscbal.png";
      const discharge = this.parseValueStations.transform(element.maxdischarge, DataLayer.Discharge);
      return `
      <div id="${element.id}">
        <div class="map-icon">
          ${stationIcon}
        </div> 
        <div class="map-card --is-${element.status} --is-station">
          <div class="map-card__complete">
            <div class="map-card__circle --is-${element.status}">
            </div>
            <div class="map-card__top">
              <ion-img class="map-card__top__img" src="${imageIcon}"></ion-img>
              <p class="map-card__top__text --h3">${element.name == null ? element.id : element.name}</p>
            </div>
            <div class="map-card__bottom">
              <p class="map-card__bottom__text --h3 --show" >${discharge}</p>
            </div>
          </div>
        </div>
      </div>
      `;
    }

    const waterLevel = this.parseValueStations.transform(element.waterLevel, DataLayer.WaterLevel);
    const precipitation = this.parseValueStations.transform(element.precipitationLast1h, DataLayer.Precipitation1h);

    if (instanceOfStationDto(element)) {
      const imageIcon = `assets/images/${element.owner === "0" ? "riscbal" : element.owner === "1" ? "aemet" : "wunderground"}.png`;
      const precipitationLast1h = this.parseValueStations.transform(element.precipitationLast1h, DataLayer.Precipitation1h);

      return `
      <div id="${element.id}">
        <div class="map-icon">
          ${stationIcon}
        </div> 
        <div class="map-card --is-${element.status} --is-station">
          <div class="map-card__complete">
            <div class="map-card__circle --is-${element.status}">
            </div>
            <div class="map-card__top">
              <ion-img class="map-card__top__img" src="${imageIcon}"></ion-img>
              <p class="map-card__top__text --h3">${element.id} - ${element.name}</p>
            </div>
            <div class="map-card__bottom">
              <p class="map-card__bottom__text --h3 ${waterLevel === "-" ? "" : "--show"}">${waterLevel}</p>
              <p class="map-card__bottom__separator --h3 ${waterLevel !== "-" && precipitationLast1h !== "-" ? "--show" : ""}">·</p>
              <p class="map-card__bottom__text --h3 ${precipitationLast1h === "-" ? "" : "--show"}">
                ${precipitationLast1h}</p>
            </div>
          </div>
        </div>
      </div>
        `;
    } else {
      const discharge = this.parseValueStations.transform(element.dischargePredicted, DataLayer.Discharge);
      const maxStatus = element.status >= element.statusPredicted ? element.status : element.statusPredicted;

      let maxValue: string;

      if (dataLayer === DataLayer.Precipitation1h) {
        maxValue = precipitation;
      } else if (dataLayer === DataLayer.WaterLevel) {
        maxValue = element.status >= element.statusPredicted ? waterLevel : discharge;
      }

      const hidden = maxValue === "-" ? "--hidden" : "";

      const elementId = instanceOfCatchmentDto(element) ? element.id + "-" + element.name : element.id;
      return `
        <div id="${elementId}" class="map-card --is-${maxStatus} ${hidden}">
          <div class="map-card__circle --is-${maxStatus}"></div>
          <div class="map-card__top">
            <p class="map-card__top__text --h3">${element.name}</p>
          </div>
          <div class="map-card__bottom --is-${maxStatus}">
            <p class="map-card__bottom__text --h3 --show">${maxValue}</p>
          </div>
        </div>
        `;
    }
  }
}
