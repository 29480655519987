import { IslandDto, StationDto, CatchmentDto, WatershedDto, BlackSpotDto } from "../services/api";

export function instanceOfStationDto(
  object: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto):
  object is StationDto {
  return "catchmentId" in object;
}

export function instanceOfCatchmentDto(
  object: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto):
  object is CatchmentDto {
  return "watershedId" in object;
}

export function instanceOfWatershedDto(
  object: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto):
  object is WatershedDto {
  return "islandId" in object;
}

export function instanceOfBlackspotDto(
  object: IslandDto | WatershedDto | CatchmentDto | StationDto | BlackSpotDto):
  object is BlackSpotDto {
  return "maxdischarge" in object;
}
