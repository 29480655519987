/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { CatchmentDto, IslandDto, WatershedDto } from "src/app/services/api";

@Pipe({ name: "orderByWaterLevel" })
export class OrderByWaterLevelPipe implements PipeTransform {
  public transform(elements: (IslandDto | WatershedDto | CatchmentDto)[]): (IslandDto | WatershedDto | CatchmentDto)[] {
    if (!elements) return [];
    return ([...elements] || []).sort((a, b) => this.sortByWaterLevel(a, b));
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }

  public sortByWaterLevel(a: (IslandDto | WatershedDto | CatchmentDto), b: (IslandDto | WatershedDto | CatchmentDto)): number{
    if (!this.isNumber(a.waterLevel) && !this.isNumber(b.waterLevel)) return a.name.localeCompare(b.name);
    else if (!this.isNumber(a.waterLevel)) return 1;
    else if (!this.isNumber(b.waterLevel)) return -1;
    const result = b.waterLevel - a.waterLevel;
    return result ? result : a.name.localeCompare(b.name);
  }
}
