import { createAction, props } from "@ngrx/store";

export const setModalHeight = createAction("[APP] Set Modal Height", props<{ modalHeight: number }>());

export const switchModalWidth = createAction("[APP] switch Modal Width");

export const refreshState = createAction("[APP] Refresh State");
export const setRefreshState = createAction("[APP] Set Refresh State", props<{ refreshState: boolean }>());

export const incrementRefreshCounter = createAction("[APP] Increment Refresh Counter");
