import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { CatchmentComponent } from "./catchment.component";
import { TranslateModule } from "@ngx-translate/core";
import { SuppressScrollEventModule } from "src/app/directives/suppress-scroll-event/suppress-scroll-event.module";
import { ShareUrlModule } from "src/app/directives/share-url/share-url.module";
import { StationCardModule } from "src/app/components/station-card/station-card.module";
import { OrderStationsByWaterLevelModule } from "src/app/pipes/order-stations-by-water-level/order-stations-by-water-level.module";
import { ParseToStationCardItemsModule } from "src/app/pipes/parse-to-station-card-items/parse-to-station-card-items.module";
import { OrderStationsByPrecipitationModule } from "src/app/pipes/order-stations-by-precipitation/order-stations-by-precipitation.module";
import { ChartModule } from "src/app/components/chart/chart.module";
import { HidroMeteoSelectorModule } from "../../components/hidrometeo-selector/hidrometeo-selector.module";

@NgModule({
  declarations: [CatchmentComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SuppressScrollEventModule,
    ShareUrlModule,
    OrderStationsByWaterLevelModule,
    StationCardModule,
    ParseToStationCardItemsModule,
    OrderStationsByPrecipitationModule,
    ChartModule,
    HidroMeteoSelectorModule,
  ],
})
export class CatchmentModule {}
