import { Component, OnInit } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as AppActions from "src/app/store/app/actions";
import { Observable, combineLatest, filter, first, tap } from "rxjs";
import * as FromUser from "src/app/store/user/selectors";
import { AppState, App } from "@capacitor/app";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  public displayIntroImage: boolean;

  public readonly accessToken$: Observable<string> = this.store.select(FromUser.selectAccessToken);
  public readonly guest$: Observable<boolean> = this.store.select(FromUser.selectGuest);

  constructor(
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly store: Store<RootState>,
  ) {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  public ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        this.displayIntroImage = e.snapshot.data?.displayIntroImage;
      }
    });

    App.addListener("appStateChange", (state: AppState) => {
      if (state.isActive) {
        combineLatest([this.accessToken$, this.guest$])
          .pipe(
            first(),
            filter(([accessToken, guest]) => !!accessToken || guest),
            tap(() => this.store.dispatch(AppActions.refreshState())),
          )
          .subscribe();
      }
    });

    setInterval(() => {
      combineLatest([this.accessToken$, this.guest$])
        .pipe(
          first(),
          filter(([accessToken, guest]) => !!accessToken || guest),
          tap(() => this.store.dispatch(AppActions.incrementRefreshCounter())),
        )
        .subscribe();
    }, 1000);
  }
}
