/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { CatchmentDto, IslandDto, WatershedDto } from "src/app/services/api";

@Pipe({ name: "orderByPrecipitation" })
export class OrderByPrecipitationPipe implements PipeTransform {
  public transform(elements: (IslandDto | WatershedDto | CatchmentDto)[]): (IslandDto | WatershedDto | CatchmentDto)[] {
    if (!elements) return [];
    return ([...elements] || []).sort((a, b) => {
      if (!this.isNumber(a.precipitationLast1h) && !this.isNumber(b.precipitationLast1h)) return a.name.localeCompare(b.name);
      else if (!this.isNumber(a.precipitationLast1h)) return 1;
      else if (!this.isNumber(b.precipitationLast1h)) return -1;
      const result = b.precipitationLast1h - a.precipitationLast1h;
      return result ? result : a.name.localeCompare(b.name);
    });
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }
}
