/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy, Router } from "@angular/router";
import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import { RootState, effects, reducers } from "./store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { localStorageSync } from "ngrx-store-localstorage";
import { environment } from "src/environments/environment";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";
import { DEFAULT_LANGUAGE } from "./constants";
import { SwUpdateService } from "./services/sw-updater/sw-updater.service";
import { IntroductionImageModule } from "./components/introduction-image/introduction-image.module";
import { HidroMeteoSelectorModule } from "./components/hidrometeo-selector/hidrometeo-selector.module";
import { ApiModule, Configuration } from "./services/api";
import { persistedKeys as appPersistedKeys } from "src/app/store/app/state";
import { persistedKeys as userPersistedKeys } from "src/app/store/user/state";
import { persistedKeys as watershedPersistedKeys } from "src/app/store/watershed/state";
import { logoutMetaReducer } from "./store/user/meta-reducer";
import { AuthInterceptor } from "./interceptors/auth/auth.interceptor";
import { VersionInterceptor } from "./interceptors/version/version.interceptor";
import { CatchmentModule } from "./modals/catchment/catchment.module";
import { StationModule } from "./modals/station/station.module";
import { ProfileEditModule } from "./modals/profile-edit/profile-edit.module";
import { WatershedModule } from "./modals/watershed/watershed.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { initApplication } from "./services/init/init-application";
import { WatershedsModule } from "./modals/watersheds/watersheds.module";
import { BlackspotModule } from "./modals/blackspot/blackspot.module";
import { ToMarkerModule } from "./pipes/to-marker/to-marker.module";
import { ToMarkerPipe } from "./pipes/to-marker/to-marker.pipe";

SentryCapacitor.init(
  {
    release: `riscbal-visor-app@${environment.release}`,
    dsn: "https://f5053127c07cfbc156ae1258fa7e95dd@o4508262257459200.ingest.de.sentry.io/4508262261456976",
    environment: environment.name,
    dist: environment.dist,
    integrations: [SentryAngular.browserTracingIntegration(), SentryAngular.replayIntegration()],
    tracesSampleRate: 0.5, // Tracing: Capture 50% of the transactions
    replaysSessionSampleRate: 0.1, //  Session Replay
    replaysOnErrorSampleRate: 1.0, // Sample rate to 100% when sampling sessions where errors occur.
    attachStacktrace: true,
    autoSessionTracking: true,
    enabled: environment.name !== "local",
  },
  SentryAngular.init,
);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export const localStorageReduxSync = (reducer: ActionReducer<RootState>): ActionReducer<any> =>
  localStorageSync({
    keys: [{ app: appPersistedKeys }, { user: userPersistedKeys }, { watershed: watershedPersistedKeys }],
    rehydrate: true,
    storageKeySerializer: key => key,
  })(reducer);

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync, logoutMetaReducer];

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.apiUrl });

@NgModule({
  declarations: [AppComponent],
  imports: [
    LeafletModule,
    LeafletMarkerClusterModule,
    BrowserModule,
    ToMarkerModule,
    HidroMeteoSelectorModule,
    IonicModule.forRoot({ mode: "ios" }),
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TranslateModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ApiModule.forRoot(setApiConfig),
    IntroductionImageModule,
    WatershedsModule,
    WatershedModule,
    CatchmentModule,
    StationModule,
    BlackspotModule,
    ProfileEditModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.name !== "local",
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    ToMarkerPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: SentryAngular.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      // deps: [SwUpdateService, TranslateService, SentryAngular.TraceService],
      deps: [SwUpdateService, TranslateService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
