import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShareUrlDirective } from "./share-url.directive";
import { ContextToastModule } from "src/app/components/context-toast/context-toast.module";

@NgModule({
  declarations: [ShareUrlDirective],
  imports: [CommonModule, ContextToastModule],
  exports: [ShareUrlDirective],
})
export class ShareUrlModule {}
