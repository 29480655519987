import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { ProfileEditComponent } from "./profile-edit.component";
import { TranslateModule } from "@ngx-translate/core";
import { UserImageModule } from "src/app/components/user-image/user-image.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatetimeModule } from "src/app/components/datetime/datetime.module";
import { InfoBannerModule } from "src/app/components/info-banner/info-banner.module";

@NgModule({
  declarations: [ProfileEditComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    UserImageModule,
    FormsModule,
    ReactiveFormsModule,
    DatetimeModule,
    InfoBannerModule,
  ],
})
export class ProfileEditModule {}
