/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { CatchmentDto, IslandDto, WatershedDto } from "src/app/services/api";
import { OrderByWaterLevelPipe } from "../order-by-water-level/order-by-water-level.pipe";

@Pipe({ name: "orderByAlertLevel" })
export class OrderByAlertLevelPipe implements PipeTransform {
  public transform(elements: (IslandDto | WatershedDto | CatchmentDto)[]): (IslandDto | WatershedDto | CatchmentDto)[] {
    if (!elements) return [];
    return ([...elements] || []).sort((a, b) => {
      if (!this.isNumber(a.status) && !this.isNumber(a.statusPredicted) && !this.isNumber(b.status) && !this.isNumber(b.statusPredicted)) {
        // If neither have ANY status compare waterlevel OR discharge
        const orderByWaterLevel = new OrderByWaterLevelPipe;
        const waterLevel_compare = orderByWaterLevel.sortByWaterLevel(a, b);
        return waterLevel_compare != 0 ? waterLevel_compare : (b.dischargePredicted - a.dischargePredicted);
      } else if (!this.isNumber(a.status) && this.isNumber(b.status)) {
        // If one has observedStatus and the other doesn't
        return 1;
      } else if (this.isNumber(a.status) && !this.isNumber(b.status)) {
        // If one has observedStatus and the other doesn't
        return -1;
      } else if (this.isNumber(a.status) && this.isNumber(b.status) && (b.status - a.status !== 0)) {
        // If both have status and is not equal
        return b.status - a.status;
      } else if (!this.isNumber(a.statusPredicted) && this.isNumber(b.statusPredicted)) {
        // If one has statusPredicted and the other doesn't
        return 1;
      } else if (this.isNumber(a.statusPredicted) && !this.isNumber(b.statusPredicted)) {
        // If one has statusPredicted and the other doesn't
        return -1;
      } else if (this.isNumber(a.statusPredicted) && this.isNumber(b.statusPredicted) && (b.statusPredicted - a.statusPredicted !== 0)) {
        // If both have statusPredicted and is not equal
        return b.statusPredicted - a.statusPredicted;
      } else {
        // If both have the SAME status AND statusPredicted compare waterlevel OR discharge
        const orderByWaterLevel = new OrderByWaterLevelPipe;
        const waterLevel_compare = orderByWaterLevel.sortByWaterLevel(a, b);
        return waterLevel_compare != 0 ? waterLevel_compare : (b.dischargePredicted - a.dischargePredicted);
      }
    });
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }
}
