import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { BlackspotComponent } from "./blackspot.component";
import { TranslateModule } from "@ngx-translate/core";
import { SuppressScrollEventModule } from "src/app/directives/suppress-scroll-event/suppress-scroll-event.module";
import { EmptyDataModule } from "src/app/components/empty-data/empty-data.module";
import { ShareUrlModule } from "src/app/directives/share-url/share-url.module";
import { ChartModule } from "src/app/components/chart/chart.module";

@NgModule({
  declarations: [BlackspotComponent],
  exports: [BlackspotComponent],
  imports: [CommonModule, IonicModule, TranslateModule, SuppressScrollEventModule, EmptyDataModule, ShareUrlModule, ChartModule],
  providers: [],
})
export class BlackspotModule {}
