import { BASE_MAP_LAYERS, MAP_LAYERS_WMS } from "src/app/constants";
import { DataLayer } from "src/app/enums";
import { BaseMapLayerConfig, MapLayerWMSConfig, WMSLayerLastUpdate } from "src/app/interfaces";
import {
  BlackSpotDataDto,
  BlackSpotDto, CatchmentDto, IslandDto, StationDataDto, StationDto, WatershedDto, ZoneDataDto,
} from "src/app/services/api";

export interface Islands {
  [id: string]: IslandDto;
}

export interface Watersheds {
  [id: string]: WatershedDto;
}

export interface Catchments {
  [id: string]: CatchmentDto;
}

export interface IslandCatchments {
  [id: string]: { [id: string]: CatchmentDto };
}

export interface CatchmentsData {
  [id: string]: ZoneDataDto;
}

export interface CatchmentStations {
  [id: string]: StationDto[];
}

export interface StationsData {
  [id: string]: StationDataDto;
}

export interface Blackspots{
  [id: string]: BlackSpotDto[];
}

export interface BlackspotsData{
  [id: string]: BlackSpotDataDto;
}

export interface WatershedState {
  islands: Islands;
  watersheds: Watersheds;
  islandId: string;
  hoveredIslandId: string;
  watershedId: number;
  hoveredWatershedId: number;
  allCatchments: Catchments;
  catchmentId: number;
  hoveredCatchmentId: number;
  catchmentsData: CatchmentsData;
  catchmentStations: CatchmentStations;
  stationCode: string;
  hoveredStationCode: string;
  stationsData: StationsData;
  catchmentWaterLevelStationCode: string;
  catchmentPrecipitationStationCode: string;
  catchmentBlackspots: Blackspots;
  blackspotsData: BlackspotsData;
  blackspotId: string;
  mapLayerConfig: BaseMapLayerConfig;
  mapLayerWMS: MapLayerWMSConfig;
  dataLayer: DataLayer;
  riversLayer: MapLayerWMSConfig;
  lastUpdatesLayerWMS: WMSLayerLastUpdate[];
  loading: boolean;
}

export const initialState: WatershedState = {
  islands: {},
  watersheds: {},
  islandId: null,
  hoveredIslandId: null,
  watershedId: null,
  hoveredWatershedId: null,
  allCatchments: {},
  catchmentId: null,
  hoveredCatchmentId: null,
  catchmentsData: {},
  catchmentStations: {},
  stationCode: null,
  hoveredStationCode: null,
  stationsData: {},
  catchmentWaterLevelStationCode: null,
  catchmentPrecipitationStationCode: null,
  catchmentBlackspots: {},
  blackspotsData: {},
  blackspotId: null,
  mapLayerConfig: BASE_MAP_LAYERS.BiN,
  mapLayerWMS: MAP_LAYERS_WMS.Precipitation1h,
  dataLayer: DataLayer.WaterLevel,
  riversLayer: MAP_LAYERS_WMS.RiversZoomOut,
  lastUpdatesLayerWMS: [],
  loading: false,
};

export const persistedKeys: (keyof WatershedState)[] = [
  "islands",
  "watersheds",
  "islandId",
  "watershedId",
  "catchmentId",
  "allCatchments",
  "catchmentsData",
  "catchmentStations",
  "stationCode",
  "stationsData",
  "catchmentWaterLevelStationCode",
  "catchmentPrecipitationStationCode",
  "catchmentBlackspots",
  "mapLayerConfig",
  "mapLayerWMS",
  "dataLayer",
  "lastUpdatesLayerWMS",
];
