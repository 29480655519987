import { Directive, HostListener, Input } from "@angular/core";
import { Share } from "@capacitor/share";
import { PopoverController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, first, tap } from "rxjs";
import { ContextToastComponent } from "src/app/components/context-toast/context-toast.component";
import { RootState } from "src/app/store";
import * as FromWatershed from "src/app/store/watershed/selectors";

@Directive({ selector: "[appShareUrl]" })
export class ShareUrlDirective {
  @Input() public appShareUrl: "islands" | "watersheds" | "watershed" | "catchment" | "station";

  public readonly islandId$: Observable<string> = this.store.select(FromWatershed.selectIslandId);
  public readonly watershedId$: Observable<number> = this.store.select(FromWatershed.selectWatershedId);
  public readonly catchmentId$: Observable<number> = this.store.select(FromWatershed.selectCatchmentId);
  public readonly stationCode$: Observable<string> = this.store.select(FromWatershed.selectStationCode);

  constructor(
    private readonly popover: PopoverController,
    private readonly t: TranslateService,
    private readonly store: Store<RootState>,
  ) {}

  @HostListener("click", ["$event"]) public async onClick($event: Event): Promise<void> {
    let url = new URL(location.href);

    switch (this.appShareUrl) {
      case "islands":
        this.setIslandId(url);
        break;
      case "watershed":
        this.setIslandId(url);
        this.setWatershedId(url);
        break;
      case "catchment":
        this.setIslandId(url);
        this.setWatershedId(url);
        this.setCatchmentId(url);
        break;
      case "station":
        this.setIslandId(url);
        this.setWatershedId(url);
        this.setCatchmentId(url);
        this.setStationCode(url);
        break;
    }

    const { value: nativeShareAvailable } = await Share.canShare();

    if (nativeShareAvailable) {
      Share.share({ url: url.toString() });
    } else {
      navigator.clipboard.writeText(url.toString());
      const copiedAlert = await this.popover.create({
        component: ContextToastComponent,
        componentProps: { status: "success", text: this.t.instant("COPIED"), iconName: "copy-outline" },
        showBackdrop: false,
        backdropDismiss: true,
        arrow: false,
        alignment: "center",
        side: "left",
        event: $event,
        cssClass: "context-popover",
      });
      copiedAlert.present();
      setTimeout(() => copiedAlert.dismiss(), 2000);
    }
  }

  private setIslandId(url: URL): void {
    this.islandId$
      .pipe(
        first(),
        tap(islandId => url.searchParams.append("islandId", islandId.toString())),
      )
      .subscribe();
  }

  private setWatershedId(url: URL): void {
    this.watershedId$
      .pipe(
        first(),
        tap(watershedId => url.searchParams.append("watershedId", watershedId.toString())),
      )
      .subscribe();
  }

  private setCatchmentId(url: URL): void {
    this.catchmentId$
      .pipe(
        first(),
        tap(catchmentId => url.searchParams.append("catchmentId", catchmentId.toString())),
      )
      .subscribe();
  }

  private setStationCode(url: URL): void {
    this.stationCode$
      .pipe(
        first(),
        tap(stationCode => url.searchParams.append("stationCode", stationCode)),
      )
      .subscribe();
  }
}
