import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderStationsByPrecipitationPipe } from "./order-stations-by-precipitation.pipe";

@NgModule({
  declarations: [OrderStationsByPrecipitationPipe],
  imports: [CommonModule],
  exports: [OrderStationsByPrecipitationPipe],
})
export class OrderStationsByPrecipitationModule {}
