import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartComponent } from "./chart.component";
import { IonicModule } from "@ionic/angular";
import { EmptyDataModule } from "../empty-data/empty-data.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputErrorsModule } from "src/app/directives/input-errors/input-errors.module";

@NgModule({
  declarations: [ChartComponent],
  exports: [ChartComponent],
  imports: [CommonModule, IonicModule, TranslateModule, EmptyDataModule, FormsModule, ReactiveFormsModule, InputErrorsModule],
})
export class ChartModule {}
