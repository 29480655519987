import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataCardComponent } from "./data-card.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [DataCardComponent],
  exports: [DataCardComponent],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class DataCardModule {}
