import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HidroMeteoSelectorComponent } from "./hidrometeo-selector.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [HidroMeteoSelectorComponent],
  exports: [HidroMeteoSelectorComponent],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class HidroMeteoSelectorModule {}
