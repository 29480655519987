import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmptyDataComponent } from "./empty-data.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [EmptyDataComponent],
  exports: [EmptyDataComponent],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class EmptyDataModule {}
