import { createReducer, on } from "@ngrx/store";
import { Catchments, Islands, WatershedState, Watersheds, initialState } from "./state";
import * as WatershedActions from "./actions";

export const watershedReducer = createReducer(
  initialState,

  on(WatershedActions.setBaseMapLayerConfig, (state, { mapLayerConfig }): WatershedState => ({ ...state, mapLayerConfig })),

  on(WatershedActions.setRiversLayer, (state, { riversLayer }): WatershedState => ({ ...state, riversLayer })),

  on(WatershedActions.setMapLayerWMS, (state, { mapLayerWMS }): WatershedState => ({ ...state, mapLayerWMS })),

  on(WatershedActions.setDataLayer, (state, { dataLayer }): WatershedState => ({ ...state, dataLayer })),

  on(WatershedActions.setIslandId, (state, { islandId }): WatershedState => ({ ...state, islandId })),

  on(WatershedActions.setWatershedId, (state, { watershedId }): WatershedState => ({ ...state, watershedId })),

  on(WatershedActions.setCatchmentId, (state, { catchmentId }): WatershedState => ({ ...state, catchmentId })),

  on(WatershedActions.setStationCode, (state, { stationCode }): WatershedState => ({ ...state, stationCode })),

  on(WatershedActions.setHoveredIslandId, (state, { hoveredIslandId }): WatershedState => ({ ...state, hoveredIslandId })),

  on(WatershedActions.setHoveredWatershedId, (state, { hoveredWatershedId }): WatershedState => ({ ...state, hoveredWatershedId })),

  on(WatershedActions.setHoveredWatershedId, (state, { hoveredWatershedId }): WatershedState => ({ ...state, hoveredWatershedId })),

  on(WatershedActions.setHoveredCatchmentId, (state, { hoveredCatchmentId }): WatershedState => ({ ...state, hoveredCatchmentId })),

  on(WatershedActions.setHoveredStationCode, (state, { hoveredStationCode }): WatershedState => ({ ...state, hoveredStationCode })),

  on(WatershedActions.setBlackspotId, (state, { blackspotId }): WatershedState => ({ ...state, blackspotId })),

  on(
    WatershedActions.setCatchmentWaterLevelStationCode,
    (state, { catchmentWaterLevelStationCode }): WatershedState => ({ ...state, catchmentWaterLevelStationCode }),
  ),

  on(
    WatershedActions.setCatchmentPrecipitationStationCode,
    (state, { catchmentPrecipitationStationCode }): WatershedState => ({ ...state, catchmentPrecipitationStationCode }),
  ),

  on(
    WatershedActions.loadIslands,
    WatershedActions.loadWatersheds,
    WatershedActions.loadWatershedCatchmentData,
    WatershedActions.loadAnyCatchmentBlackspots,
    WatershedActions.loadBlackspotData,
    WatershedActions.loadStationData,
    WatershedActions.loadWMSLayerLastUpdate,
    (state): WatershedState => ({
      ...state,
      loading: true,
    }),
  ),

  on(WatershedActions.resetState,
    (initialState): WatershedState => ({
      ...initialState,
    }),
  ),

  on(
    WatershedActions.loadIslandsFailure,
    WatershedActions.loadWatershedsFailure,
    WatershedActions.loadWatershedCatchmentDataFailure,
    WatershedActions.loadBlackspotDataFailure,
    WatershedActions.loadWatershedCatchmentStationsFailure,
    WatershedActions.loadStationDataFailure,
    WatershedActions.loadWMSLayerLastUpdateFailure,
    (state): WatershedState => ({ ...state, loading: false }),
  ),

  on(WatershedActions.loadIslandsSuccess, (state, { islands }): WatershedState => {
    const entities: Islands = {};
    islands.forEach(island => (entities[island.id] = island));
    return {
      ...state,
      islands: entities,
      loading: false,
    };
  }),

  on(WatershedActions.loadAllCatchmentsSuccess, (state, { catchments }): WatershedState => {
    const entities: Catchments = {};
    catchments.forEach(catchment => (entities[catchment.id] = catchment));
    return {
      ...state,
      allCatchments: {
        ...state.allCatchments,
        ...entities,
      },
      loading: false,
    };
  }),

  on(WatershedActions.loadWatershedsSuccess, (state, { watersheds }): WatershedState => {
    const entities: Watersheds = {};
    watersheds.forEach(watershed => (entities[watershed.id] = watershed));
    return {
      ...state,
      watersheds: entities,
      loading: false,
    };
  }),

  on(
    WatershedActions.loadWatershedCatchmentDataSuccess,
    (state, { catchmentId, data }): WatershedState => ({
      ...state,
      catchmentsData: {
        ...state.catchmentsData,
        [catchmentId]: data,
      },
      loading: false,
    }),
  ),

  on(
    WatershedActions.loadAnyCatchmentBlackspotsSuccess,
    (state, { catchmentId, data }): WatershedState => ({
      ...state,
      catchmentBlackspots: {
        ...state.catchmentBlackspots,
        [catchmentId]: data,
      },
      loading: false,
    }),
  ),

  on(
    WatershedActions.loadBlackspotDataSuccess,
    (state, { blackspotId, blackspotsData }): WatershedState => ({
      ...state,
      blackspotsData: {
        ...state.blackspotsData,
        [blackspotId]: blackspotsData,
      },
      loading: false,
    }),
  ),

  on(
    WatershedActions.setWatershedName,
    (state, { watershedNameId, watershedName }): WatershedState => ({
      ...state,
      watersheds: {
        ...state.watersheds,
        [watershedNameId]: {
          ...state.watersheds[watershedNameId],
          name: watershedName,
        },
      },
    }),
  ),

  on(
    WatershedActions.loadAnyCatchmentStationsSuccess,
    (state, { catchmentId, stations }): WatershedState => ({
      ...state,
      catchmentStations: {
        ...state.catchmentStations,
        [catchmentId]: stations,
      },
      loading: false,
    }),
  ),

  on(
    WatershedActions.setWatershedId,
    (state): WatershedState => ({
      ...state,
      catchmentStations: {},
      loading: false,
    }),
  ),

  on(
    WatershedActions.loadStationDataSuccess,
    (state, { stationCode, stationData }): WatershedState => ({
      ...state,
      stationsData: {
        ...state.stationsData,
        [stationCode]: stationData,
      },
      loading: false,
    }),
  ),

  on(
    WatershedActions.loadWMSLayerLastUpdateSuccess,
    (state, { lastUpdatesLayerWMS }): WatershedState => ({
      ...state,
      lastUpdatesLayerWMS,
      loading: false,
    }),
  ),

);
