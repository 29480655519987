<div class="layers-manager__popover__content">
  <div class="layers-manager__popover__content__bottom">
      <div
          class="layers-manager__popover__content__bottom__element --clickable"
          (click)="selectDataToShow(dataLayer.WaterLevel)"
      >
          <div
              class="layers-manager__popover__content__bottom__element__img"
              [class.--selected]="(dataLayer$ | async) === dataLayer.WaterLevel"
          >
              <img class="--small" src="assets/images/water-level.png" />
          </div>
          <p class="--small" [innerHTML]="'LAYERS_MANAGER_COMPONENT.DATA_LAYER.Hidro' | translate"></p>
      </div>
      <div
          class="layers-manager__popover__content__bottom__element --clickable"
          (click)="selectDataToShow(dataLayer.Precipitation1h)"
      >
          <div
              class="layers-manager__popover__content__bottom__element__img"
              [class.--selected]="(dataLayer$ | async) === dataLayer.Precipitation1h"
          >
              <img class="--small" src="assets/images/rain.png" />
          </div>
          <p class="--small" [innerHTML]="'LAYERS_MANAGER_COMPONENT.DATA_LAYER.Meteo' | translate"></p>
      </div>
  </div>
</div>
