/* eslint-disable @typescript-eslint/no-explicit-any */
import * as UserActions from "./actions";
import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of, catchError, map, mergeMap, tap, delay, withLatestFrom } from "rxjs";
import { AuthService, LegacyService } from "src/app/services/api";
import { AppRoutes } from "src/app/enums";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class UserEffects {
  public login$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      mergeMap(params =>
        this.authService.authenticationControllerLogin(params).pipe(
          mergeMap(({ accessToken, refreshToken }) => of(UserActions.loginSuccess({ accessToken, refreshToken }))),
          catchError((error: HttpErrorResponse) => of(UserActions.loginFailure({ error: error.error.message }))),
        ),
      ),
    ),
  );

  public loginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        delay(1000),
        tap(() => this.navController.navigateForward([AppRoutes.map])),
      ),
    { dispatch: false },
  );

  public guestLogin$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.guestLogin),
      mergeMap(params =>
        this.authService.authenticationControllerLogin(params).pipe(
          mergeMap(({ accessToken, refreshToken }) => of(UserActions.loginSuccess({ accessToken, refreshToken }))),
          catchError((error: HttpErrorResponse) => of(UserActions.loginFailure({ error: error.error.message }))),
        ),
      ),
    ),
  );

  public loadUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      mergeMap(() =>
        this.authService.authenticationControllerGetUserSelf().pipe(
          map(user => UserActions.loadUserSuccess({ user })),
          catchError(() => of(UserActions.loadUserFailure())),
        ),
      ),
    ),
  );

  public logout$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => {
          localStorage.clear();
          this.navController.navigateRoot([AppRoutes.login]);
          window.location.reload();
        }),
      ),
    { dispatch: false },
  );

  public updateUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap(params =>
        this.authService.authenticationControllerUpdateUserSelf(params).pipe(
          map(user => UserActions.updateUserSuccess({ user })),
          catchError((error: HttpErrorResponse) => of(UserActions.updateUserFailure({ error: error.error.message }))),
        ),
      ),
    ),
  );

  public updateUserSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, UserActions.updateUserSuccess),
      delay(1000),
      map(() => UserActions.setUpdateSuccess({ updateSuccess: false })),
    ),
  );

  public loadUserCatchmentPosition$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserCurrentPosition),
      mergeMap(params =>
        this.legacyService.legacyControllerGetPointLocation(params).pipe(
          map(data => UserActions.loadUserCurrentPositionSuccess({ userPosition: data })),
          catchError(() => of(UserActions.loadUserCurrentPositionFailure())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly navController: NavController,
    private readonly legacyService: LegacyService,
  ) {}
}
