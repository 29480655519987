<div class="chart" [class.--half-veil]="stations">
    <app-empty-data [display]="!data?.length" [text]="'NO_DATA' | translate"></app-empty-data>

    @if (precipitationLast1h || precipitationLast24h) {
        <div class="station__content__card">
            <ion-card class="station__content__card__summary">
                <img src="assets/images/rain.png" />
                <div class="station__content__card__summary__values">
                    @if (precipitationLast1h) {
                        <div class="station__content__card__summary__value">
                            <p class="station__content__card__summary__title --h1" [innerHTML]="precipitationLast1h"></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.PRECIPITATION_ACCUMULATED_1H' | translate"
                            ></p>
                        </div>
                    }
                    @if (precipitationLast24h) {
                        <div class="station__content__card__summary__value">
                            <p class="station__content__card__summary__title --h1" [innerHTML]="precipitationLast24h"></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.PRECIPITATION_ACCUMULATED_24H' | translate"
                            ></p>
                        </div>
                    }
                </div>
            </ion-card>
        </div>
    }

    <canvas #chartContainer></canvas>

    @if (stations) {
        <ion-item [formGroup]="form">
            <ion-select
                appInputErrors
                formControlName="station"
                interface="popover"
                name="station"
                [cancelText]="'CANCEL' | translate"
                [placeholder]="'SELECT' | translate"
                (ionChange)="selectStation.emit($event.detail.value)"
            >
                @for (station of stations; track station.id) {
                    <ion-select-option [value]="station.id">
                        {{ station.id + " - " + station.name }}
                    </ion-select-option>
                }
            </ion-select>
        </ion-item>
    }
</div>
