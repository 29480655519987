/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError, catchError, first, mergeMap, switchMap } from "rxjs";
import { NO_INTERCEPTOR_REQUESTS, NO_INTERCEPTOR_ROUTES, PLUJA_RADAR_URL } from "src/app/constants";
import { AuthService } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as UserActions from "src/app/store/user/actions";
import * as FromUser from "src/app/store/user/selectors";

const noAuthUrls = [PLUJA_RADAR_URL];

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private refreshToken: string;

  constructor(
    private readonly store: Store<RootState>,
    private readonly authService: AuthService,
  ) {
    this.store.select(FromUser.selectRefreshToken).subscribe(token => (this.refreshToken = token));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromUser.selectAccessToken).pipe(
      first(),
      switchMap(token => {
        if (token && !noAuthUrls.includes(req.url)) req = req.clone({ setHeaders: { authorization: `Bearer ${token}` } });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= 500) console.error(error);

            if (
              error.status === 401 &&
              !NO_INTERCEPTOR_ROUTES.some(route => req.url.includes(route)) &&
              !NO_INTERCEPTOR_REQUESTS.some(request => req.url.includes(request))
            ) {
              if (!!this.refreshToken) {
                return this.authService.authenticationControllerRefresh({ refreshTokenDto: { refreshToken: this.refreshToken } }).pipe(
                  mergeMap(tokens => {
                    this.store.dispatch(UserActions.setAccessToken({ accessToken: tokens.accessToken }));
                    this.store.dispatch(UserActions.setRefreshToken({ refreshToken: tokens.refreshToken }));
                    return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${tokens.accessToken}` } }));
                  }),
                  catchError((e: any) => {
                    console.error(error);
                    if (e.status === 401) this.store.dispatch(UserActions.logout());
                    return throwError(() => e);
                  }),
                );
              }
              return throwError(() => error);
            }
            return throwError(() => error);
          }),
        );
      }),
      catchError(error => throwError(() => error)),
    );
  }
}
