<ion-card class="station-card --clickable" [class.--hovered]="hovered">
    @if (status) {
        <div [class]="'station-card__status ' + '--' + status"></div>
    }
    <div class="station-card__top">
        <img [src]="icon" />
        <div class="station-card__top__content">
            <p class="station-card__top__content__title --h3" [innerHTML]="title"></p>
            <p class="station-card__top__content__subtitle --small" [innerHTML]="subtitle"></p>
        </div>
    </div>
    @if (items?.length) {
        <div class="station-card__bottom">
            <div class="station-card__bottom__top">
                <img [src]="descriptionIcon" />
                <p class="station-card__bottom__top__text --h3" [innerHTML]="description"></p>
            </div>
            <div class="station-card__bottom__bottom">
                @for (item of items; track $index) {
                    <div class="station-card__bottom__bottom__item">
                        <p class="station-card__bottom__bottom__item__title --h3" [innerHTML]="item.title"></p>
                        <p class="station-card__bottom__bottom__item__subtitle --small" [innerHTML]="item.subtitle"></p>
                    </div>
                }
            </div>
        </div>
    }
</ion-card>
